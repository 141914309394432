import Router from 'next/router';

import { format, getDateMonth, localDateToJsDate } from 'Utils/dateUtils';

import {
	BANNER_IMAGE_SIZE,
	BANNER_PLATFORM,
	BLOGS_SUPPORTED_LANG,
	COUNTRY_CODES,
	LANGS_WITH_COMMA_IN_PRICE,
	PAGE_TYPE,
	PRODUCT_BANNER_SIZE,
	STORE_ENTITY_TYPE,
	STORE_JS_VERSION,
	UNIT_ABBREVIATIONS,
} from 'Constants/constants';
import type { ICurrency } from 'Constants/currency';
import { strings } from 'Constants/strings';

import { getLocalisedPrice, isValidPrice } from './currencyUtils';
import { clear, read, write } from './localStorageUtils';
import { replaceDotByComma, stripUnnecessaryDecimals } from './stringUtils';

export const truncateWithEllipsis = ({ text = '', limit = 54 }) => {
	if (text?.length < limit) {
		return text;
	}

	if (limit <= 3) {
		return text?.substring(0, limit);
	}

	return `${text?.substring(0, limit - 4)?.trim()}...`;
};

export const getProfileText = (ageFrom: any, ageTo: any) => {
	if (ageFrom) {
		if (ageTo) {
			return strings.formatString(strings.CP_X_TO_Y_YEAR, ageFrom, ageTo);
		}
		return strings.formatString(strings.CP_ABOVE_X_YEAR, ageFrom);
	}
	if (ageTo) {
		return strings.formatString(strings.CP_UNDER_X_YEAR, ageTo);
	}
	return strings.CP_ANY_AGE;
};

export const formatPrice = (
	price: number | null | undefined,
	currency: ICurrency,
) => {
	const lang = Router?.router?.query?.lang || 'en';

	if (isValidPrice(price)) {
		return getLocalisedPrice(price as number, currency, lang as string);
	}
	return '';
};

export const convertibleFormatPrice = (user: any) => {
	const {
		walletCredits,
		walletCurrency,
		baseCurrency,
		walletToBaseCurrencyConversionRate,
	} = user;
	const { code } = walletCurrency;
	const { code: baseCurrencyCode } = baseCurrency;

	if (walletCredits > 0 && code !== baseCurrencyCode) {
		return formatPrice(
			walletCredits * walletToBaseCurrencyConversionRate,
			baseCurrency,
		);
	}
	return formatPrice(walletCredits, walletCurrency);
};

export const floorPrice = (price: any) => {
	return Math.floor(parseFloat(price));
};

export const formatPriceValue = (
	price: any,
	currency: any,
	truncate = false,
	truncateAfter = 3,
	lang: any,
) => {
	const { precision } = currency;
	const priceAsString = toFixedWithPrecision(
		parseFloat(price),
		precision || 2,
	);

	const replaceDotByCommaInPrice = LANGS_WITH_COMMA_IN_PRICE.includes(lang);

	if (truncate) {
		const truncatedPrice = truncateNumber(
			// @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
			parseFloat(priceAsString),
			truncateAfter,
		);
		return replaceDotByCommaInPrice
			? replaceDotByComma(truncatedPrice)
			: truncatedPrice;
	}

	const priceWithUnNecessaryDecimalsStripped = stripUnnecessaryDecimals(
		// @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		parseFloat(priceAsString).toFixed(precision).toLocaleString(),
	);

	return replaceDotByCommaInPrice
		? replaceDotByComma(priceWithUnNecessaryDecimalsStripped)
		: priceWithUnNecessaryDecimalsStripped;
};

export const truncateNumber = (num = 0, truncateAfter = 3) => {
	if (num < 10 ** (truncateAfter - 1)) return num.toString();
	let truncatedNumber = num;
	for (let i = UNIT_ABBREVIATIONS.length - 1; i >= 0; i--) {
		const truncationSize = 10 ** ((i + 1) * 3);
		if (num >= truncationSize) {
			// @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
			truncatedNumber =
				Number(
					toFixedWithPrecision(num / truncationSize, 1),
				).toLocaleString() + UNIT_ABBREVIATIONS[i];
			break;
		}
	}
	return truncatedNumber.toString();
};

export const toFixedWithPrecision = (num: any, precision: any) => {
	const precisionExp = 10 ** precision;
	return Math.trunc(Math.round(num * precisionExp)) / precisionExp;
};

/* @deprecated */
export const clone = (obj: any) => {
	return obj;
};

export const sanitizeStore = () => {
	const storejsVersionInBrowser = read(`${STORE_ENTITY_TYPE.STORE_VERSION}`);
	if (storejsVersionInBrowser) {
		if (storejsVersionInBrowser !== STORE_JS_VERSION) {
			clear();
		}
	}
	write(`${STORE_ENTITY_TYPE.STORE_VERSION}`, STORE_JS_VERSION);
};

export const isObject = (item: any) => {
	return item && typeof item === 'object' && !Array.isArray(item);
};

export const customMergeDeep = (target: any, source: any) => {
	let output = Object.assign({}, target);
	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach(key => {
			if (isObject(source[key])) {
				if (!(key in target))
					Object.assign(output, { [key]: source[key] });
				else output[key] = customMergeDeep(target[key], source[key]);
			} else {
				Object.assign(output, { [key]: source[key] });
			}
		});
	}
	return output;
};

export const getSanitizedCityName = (cityName: any) => {
	return cityName.toUpperCase().replace(/-/g, '_');
};

export const selectTextInNode = (node: any) => {
	if ((document as any).selection) {
		const range = (document.body as any).createTextRange();
		range.moveToElementText(node);
		range.select();
	} else if (window.getSelection) {
		const range = document.createRange();
		range.selectNodeContents(node);
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		window.getSelection().removeAllRanges();
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		window.getSelection().addRange(range);
	}
};

export const htmlToString = (html: any) => {
	if (!html) return html;
	const str = html.replace(/<\/?[^>]+(>|$)/g, ' ');
	return str.replace(/"/g, '“');
};

export const jsonEquals = (a: any, b: any) => {
	return JSON.stringify(a) === JSON.stringify(b);
};

export const hashCode = (input: any) => {
	let hash = 0;
	if (input.length === 0) return hash;
	for (let i = 0; i < input.length; i++) {
		const char = input.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash &= hash; // Convert to 32bit integer
	}
	return hash;
};

/**
 * Combines dest with source with unique constraint
 * fallback to spread operator post upgrading - target > ES2015
 */
export const combineUnique = (oldItems: string[] = [], newItems: string[]) =>
	Array.from(new Set([...oldItems, ...newItems]));

/**
 * @param start integer
 * @param end integer
 * @returns array of numbers from minOf(start, end) to maxOf(start, end)(exclusive)
 */
export const range = (start: any, end: any) => {
	let s = start;
	let e = end;
	if (e < s) {
		s = end;
		e = start;
	}

	return new Array(e - s).fill(0).map((_, i) => i + s);
};

export const checkTimerBannerStrip = () =>
	!!document?.querySelector('.timer-banner');

export const isEven = (n: any) => {
	return n === 0 || !!(n && !(n % 2));
};

export const getFirstOccurringDate = (datesArray: any) => {
	if (datesArray?.length === 0) return null;
	let closestDateIndex = 0;
	let closestDate = Infinity;
	datesArray.forEach((date: any, i: any) => {
		// @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
		const dateObj = Date.now(date);
		if (dateObj < closestDate) {
			closestDate = dateObj;
			closestDateIndex = i;
		}
	});
	return format(
		localDateToJsDate(datesArray[closestDateIndex]),
		'mmmm d, yyyy',
	);
};

export const ifIntersectionBetweenArraysExists = (a: any, b: any) => {
	// @ts-expect-error TS(2802): Type 'Set<unknown>' can only be iterated through w... Remove this comment to see the full error message
	return [...new Set(a)].filter(x => new Set(b)?.[x]).length > 0;
};

export const removeSubArrayFromArray = (a: any, b: any) => {
	// @ts-expect-error TS(2802): Type 'Set<unknown>' can only be iterated through w... Remove this comment to see the full error message
	return Array.from(new Set([...new Set(a)].filter(x => !new Set(b)?.[x])));
};

export const makeTwoDigit = (number: any) => `0${number}`.slice(-2);

export const assignPositiveValuedPropertyToObject = (
	object: any,
	value: any,
	key: any,
) => (value ? { ...object, [key]: value } : object);

export const getNodeFromClassName = (className: any) => {
	if (document) {
		return document.querySelector(`.${className}`);
	}
	return null;
};

export const getHeightForContainerInPixels = (node: any) =>
	node && node.getBoundingClientRect()
		? node.getBoundingClientRect().height
		: 0;

export const getWidthForContainerInPixels = (node: any) =>
	node && node.getBoundingClientRect()
		? node.getBoundingClientRect().width
		: 0;

export const getLeftForContainerInPixels = (node: any) =>
	node && node.getBoundingClientRect()
		? node.getBoundingClientRect().left
		: 0;

export const takeOutKeys = (obj: any) => Object.keys(obj)?.sort();

export const fromEntries = (iterable: any) =>
	[...iterable].reduce(
		(obj, [key, val]) => Object.assign(obj, { [key]: val }),
		{},
	);

export const isEmptyString = (val: any) => val && val.trim().length > 0;

export const isNonEmptyObject = (obj: any) =>
	obj && typeof obj === 'object' && Object.keys(obj).length > 0;

// @ts-expect-error TS(7006): Parameter 'pageType' implicitly has an 'any' type.
export const getBannerDimensions = (pageType, isMobile = false) => {
	const platformType = isMobile
		? BANNER_PLATFORM.MOBILE
		: BANNER_PLATFORM.DESKTOP;
	if (pageType === PAGE_TYPE.EXPERIENCE) {
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		const height = PRODUCT_BANNER_SIZE[platformType].HEIGHT;
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		const width = PRODUCT_BANNER_SIZE[platformType].WIDTH;
		return { height, width };
	}
	// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
	const height = BANNER_IMAGE_SIZE[platformType].HEIGHT;
	// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
	const width = BANNER_IMAGE_SIZE[platformType].WIDTH;
	return { height, width };
};

export const filterPredicateFactory = () => {
	const searchResultsByQuery = {};
	return (results: any) => {
		const { query, list } = results;
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		if (searchResultsByQuery[query]) {
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			return searchResultsByQuery[query];
		}
		const filteredList = list.filter((listElement: any) => {
			const { selectionText } = listElement;
			return selectionText?.toLowerCase().includes(query.toLowerCase());
		});
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		searchResultsByQuery[query] = filteredList;
		return filteredList;
	};
};

export const getAppMetaTags = () => {
	const tags = [
		'<meta name="twitter:card" content="app" />',
		'<meta name="twitter:site" content="@headout" />',
		'<meta name="twitter:description" content="Headout Inc. is an on-demand mobile concierge service that helps travelers and natives to discover and book tours, local experiences and various other activities on the go." />',
		'<meta name="twitter:app:id:iphone" content="899327000" />',
		'<meta name="twitter:app:id:ipad" content="899327000" />',
		'<meta name="twitter:app:id:googleplay" content="com.tourlandish.chronos" />',
	];
	return tags.reduce((tag, allTags) => `${allTags} \n ${tag}`, '');
};

export const getMobileHeaderHeight = () => {
	const headerNode = document.querySelector('.mobile-header');
	return (headerNode as any).offsetHeight;
};

// @ts-expect-error TS(7006): Parameter 'className' implicitly has an 'any' type... Remove this comment to see the full error message
export const checkIfClassNameExistsInList = (list = [], className) => {
	let result = false;
	list.forEach(node => {
		result = !!(
			node &&
			(node as any).className &&
			String((node as any).className).trim() === className
		);
	});
	return result;
};

export const sortObjectAlphabetically = (objIds: any) =>
	objIds.sort((a: any, b: any) => a.localeCompare(b));

export const flatten = (obj: any) => {
	let flattenedArray = [];
	for (const item in obj) {
		flattenedArray.push(...obj[item]);
	}
	return flattenedArray;
};

// @ts-expect-error TS(7006): Parameter 'func' implicitly has an 'any' type.
export function debounce(func, wait, immediate = false) {
	// @ts-expect-error TS(7034): Variable 'timeout' implicitly has type 'any' in so... Remove this comment to see the full error message
	let timeout;

	function cancel() {
		// @ts-expect-error TS(7005): Variable 'timeout' implicitly has an 'any' type.
		if (timeout !== undefined) {
			// @ts-expect-error TS(7005): Variable 'timeout' implicitly has an 'any' type.
			clearTimeout(timeout);
		}
	}

	function debouncedFn() {
		// @ts-expect-error TS(2683): 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
		let context = this,
			args = arguments;
		// @ts-expect-error TS(7005): Variable 'timeout' implicitly has an 'any' type.
		clearTimeout(timeout);
		timeout = setTimeout(function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	}

	debouncedFn.cancel = cancel;
	return debouncedFn;
}

export const throttle = (func: any, delay: any) => {
	let flag = true;

	// @ts-expect-error TS(7019): Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
	return function (...args) {
		// @ts-expect-error TS(2683): 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
		let context = this;

		if (flag) {
			func.apply(context, args);
			flag = false;

			setTimeout(() => {
				flag = true;
			}, delay);
		}
	};
};

export const throttleAdv = (
	func: any,
	wait: any,
	option = { leading: true, trailing: true },
) => {
	if (!option.leading && !option.trailing) return () => null;
	let waiting = false;
	// @ts-expect-error TS(7034): Variable 'lastArgs' implicitly has type 'any' in s... Remove this comment to see the full error message
	let lastArgs;
	// @ts-expect-error TS(7034): Variable 'timeoutId' implicitly has type 'any' in ... Remove this comment to see the full error message
	let timeoutId = null;
	const timeoutFn = (context: any) => {
		timeoutId = setTimeout(() => {
			// @ts-expect-error TS(7005): Variable 'lastArgs' implicitly has an 'any' type.
			if (option.trailing && lastArgs) {
				func.apply(context, lastArgs);
				lastArgs = null;
				// @ts-expect-error TS(7005): Variable 'timeoutId' implicitly has an 'any' type.
				if (timeoutId) timeoutId = null;
				timeoutFn(context);
			} else {
				waiting = false;
			}
		}, wait);
	};

	return function () {
		if (!waiting) {
			waiting = true;
			// @ts-expect-error TS(2683): 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
			if (option.leading) func.apply(this, arguments);
			// @ts-expect-error TS(2683): 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
			timeoutFn(this);
		} else {
			// @ts-expect-error TS(2802): Type 'IArguments' can only be iterated through whe... Remove this comment to see the full error message
			lastArgs = [...arguments];
		}
	};
};

export const isWhatsAppUser = (userCountryCode: any) =>
	userCountryCode !== COUNTRY_CODES.US.ISO2;

export const isErrorStatusCode = (statusCode: any) =>
	/[45][\d]{2}/.test(statusCode);

export const checkIfErrorPage = (pageType: any) =>
	[PAGE_TYPE.ERROR, PAGE_TYPE.NOT_FOUND].includes(pageType);

export const scrollElementToBelowHeader = (
	elementRef: HTMLElement,
	offset = 0,
	isMobile = false,
) => {
	const containerCurrentYPos = parseInt(
		elementRef.getBoundingClientRect().top.toString(),
	);

	const elementSelector = !isMobile ? '.header-wrapper' : '.mobile-header';

	const headerHeight = parseInt(
		document
			.querySelector(elementSelector)
			?.getBoundingClientRect()
			.height.toString()!,
	);
	if (containerCurrentYPos !== headerHeight)
		window.scrollTo({
			top:
				containerCurrentYPos +
				window.pageYOffset -
				headerHeight -
				offset,
			behavior: 'smooth',
		});
};

export const isBlogSupportedLang = (currentLanguage: any) =>
	BLOGS_SUPPORTED_LANG.some(lang => lang === currentLanguage);
export const requestInterval = (fn: any, delay: any) => {
	let stop = false;
	let requestAnimFrame = (() => {
			return (
				window.requestAnimationFrame ||
				function (callback) {
					window.setTimeout(callback, 1000 / 60);
				}
			);
		})(),
		start = new Date().getTime(),
		handle = {
			stop: () => {
				stop = true;
			},
		};

	function loop() {
		if (!stop) (handle as any).value = requestAnimFrame(loop);
		let current = new Date().getTime(),
			delta = current - start;
		if (delta >= delay) {
			fn();
			start = new Date().getTime();
		}
	}

	(handle as any).value = requestAnimFrame(loop);
	return handle;
};

export const checkIfStudentDiscountPage = (pageType: any) =>
	pageType === PAGE_TYPE.STUDENT_GRADUATE_DISCOUNT;

export const checkIfSitemapPage = (pageType: string) =>
	pageType === PAGE_TYPE.CITIES_SITEMAP ||
	pageType === PAGE_TYPE.COLLECTIONS_SITEMAP;

export const generateRandomArray = (range: any) => {
	let currentIndex = range,
		randomIndex;
	const array = Array.from({ length: range }).map((_, i) => i);
	while (currentIndex != 0) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
};

/**
 * Queries the dom element multiple times over an interval (warning: should not be used commonly)
 * @param selector string: DOM Selector: id, class etc.
 * @param tries number: No of times we want to try
 * @param delay number: Delay between each query attempt
 */
export const deseparatelyGetDomEl = (selector: any, tries = 3, delay = 500) => {
	return new Promise((resolve, reject) => {
		let count = 0;
		const timerId = setInterval(() => {
			const domEl = document.querySelector(selector);
			if (domEl) {
				clearInterval(timerId);
				resolve(domEl);
			} else {
				if (count < tries) count++;
				else {
					clearInterval(timerId);
					reject(new Error('DOM element could not be found'));
				}
			}
		}, delay);
	});
};

export const deferByMs = (ms = 0) =>
	new Promise(resolve => {
		setTimeout(resolve, ms || 0);
	});

let allAccordions: NodeListOf<Element> | null;
export const getAccordionIndex = ({
	target,
	selector,
}: {
	target: EventTarget;
	selector: string;
}) => {
	if (!allAccordions?.length) {
		try {
			allAccordions = document.querySelectorAll(
				selector,
			) as NodeListOf<Element> | null;
			if (!allAccordions) {
				throw Error(selector + ' DOM elements not be found');
			}
		} catch (err) {
			return -1;
		}
	}
	return Array.from(allAccordions).findIndex(
		node => target === node || node.contains(target as Element),
	);
};

export const stopEventPropagation = (
	event: React.MouseEvent<HTMLDivElement>,
	action: (...args: any) => void,
) => {
	event.preventDefault();
	event.stopPropagation();
	event.nativeEvent.stopImmediatePropagation();
	action();
};

export const onEnterKeyPress = (
	e: React.KeyboardEvent,
	callbackFunction: Function,
	...args: any[]
) => {
	if (e.key === 'Enter') callbackFunction(...args);
};

export const onEnterAndSpaceKeyPress = (
	e: React.KeyboardEvent,
	callbackFunction: Function,
	...args: any[]
) => {
	if (e.key === 'Enter') callbackFunction(...args);
	else if (e.key === ' ' || e.code === 'Space') {
		callbackFunction(...args);
	}
};

export const createSanitisedIdentifer = (str: string) =>
	str?.toUpperCase()?.replace(/ /g, '_')?.replace(/-/g, '_');

export const generateFilterKey = (...ids: Array<string | number>) =>
	ids.join('-');

export function getRandomNumberBetween(from: number, to: number): number {
	if (from > to) {
		[from, to] = [to, from];
	}
	const result = Math.random() * (to - from) + from;

	// Check if either of the input values is decimal
	// and round the result to 1 decimal place if true
	if (!Number.isInteger(from) || !Number.isInteger(to)) {
		return Math.round(result * 10) / 10;
	}
	return result;
}

export const checkIsCorrectNumType = (
	value: any,
	dataType: 'INT' | 'FLOAT',
) => {
	if (value && (dataType === 'INT' || dataType === 'FLOAT') && isNaN(value)) {
		return false;
	} else if (
		value &&
		dataType === 'INT' &&
		!isNaN(value) &&
		!Number.isInteger(parseFloat(value))
	)
		return false;
	return true;
};

export const isSubstring = (
	str: string,
	substr: string,
	caseInsensitive: boolean,
) => {
	if (caseInsensitive)
		return str?.toUpperCase()?.search(substr?.toUpperCase()) !== -1;
	return str?.search(substr) !== -1;
};

export const partialObjectEquals = <
	A extends Record<string, any>,
	B extends Record<string, any>,
>(
	objectA: A,
	objectB: B,
	exclusionKeyList: Array<keyof A | keyof B>,
): boolean => {
	if (!objectA || !objectB) return false;
	const keysA = Object.keys(objectA ?? {});
	const keysB = Object.keys(objectB ?? {});

	return keysA.concat(keysB).every(key => {
		if (exclusionKeyList.includes(key)) return true;
		return objectA[key] === objectB[key];
	});
};

export const buildDateListEntry = (
	date: string,
	label: string | null = null,
) => ({
	label: label || getDateMonth(date),
	value: date,
});

export function isElementInViewport(
	el: HTMLElement,
	offsetTop = 0,
	offsetBottom = 0,
) {
	const rect = el.getBoundingClientRect();

	return (
		rect.top >= offsetTop &&
		rect.bottom <=
			(window.innerHeight - offsetBottom ||
				document.documentElement.clientHeight - offsetBottom)
	);
}

export function isFirstElementNested(markdown: string): boolean {
	const lines: string[] = markdown.split('\n');
	const nonEmptyLines: string[] = lines.filter(
		(line: string) => line.trim() !== '',
	);
	if (nonEmptyLines.length === 0) return false;

	const firstLineIndentation: number = nonEmptyLines[0].search(/\S|$/);

	return nonEmptyLines.slice(1).some((line: string) => {
		if (line.trim().startsWith('-') || line.trim().startsWith('*')) {
			const currentLineIndentation: number = line.search(/\S|$/);
			return currentLineIndentation > firstLineIndentation;
		}
		return false;
	});
}

export function parseMarkdownToList(markdown: string): string[] {
	const lines: string[] = markdown?.split('\n');
	const result: string[] = [];
	const stack: { level: number; list: any[] }[] = [
		{ level: 0, list: result },
	];

	lines?.forEach((line: string) => {
		const match = line.match(/^(\s*)- (.*)/);
		if (match) {
			const indent: number = match[1].length;
			const text: string = match[2];
			const level: number = indent / 4 + 1;

			while (stack.length > 1 && stack[stack.length - 1].level >= level) {
				stack.pop();
			}

			const currentList = stack[stack.length - 1].list;
			const newItem = { text, children: [] };
			currentList.push(newItem);
			stack.push({ level, list: newItem.children });
		} else if (line.trim()) {
			const currentList = stack[stack.length - 1].list;
			currentList.push({ text: line.trim(), children: [] });
		}
	});

	function flattenList(items: any[]): any[] {
		return items?.map(item => {
			if (item?.children?.length > 0) {
				return {
					text: item?.text,
					children: flattenList(item?.children),
				};
			}
			return item?.text;
		});
	}

	return flattenList(result);
}
export const checkIframeEmbedValid = (headers: any, origin: any) => {
	const xFrameOptions = headers.get('x-frame-options');
	const contentSecurityPolicy = headers.get('content-security-policy');
	const accessControlAllowOrigin = headers.get('access-control-allow-origin');
	const accessControlAllowCredentials = headers.get(
		'access-control-allow-credentials',
	);

	if (xFrameOptions) {
		if (
			xFrameOptions === 'DENY' ||
			xFrameOptions === 'SAMEORIGIN' ||
			xFrameOptions.startsWith('ALLOW-FROM')
		) {
			return false;
		}
	}

	if (contentSecurityPolicy) {
		const cspDirectives = contentSecurityPolicy
			.split(';')
			.map((d: any) => d.trim());
		const frameAncestorsDirective = cspDirectives.find((d: any) =>
			d.startsWith('frame-ancestors'),
		);
		if (frameAncestorsDirective) {
			return false;
		}
	}

	if (accessControlAllowCredentials === 'true') {
		if (accessControlAllowOrigin !== origin) {
			return false;
		}
	}

	if (accessControlAllowOrigin) {
		if (
			accessControlAllowOrigin !== '*' &&
			accessControlAllowOrigin !== origin
		) {
			return false;
		}
	}

	return true;
};

/**
 * Converts a pixel value to rem units based on a base font size of 16.
 *
 * @param {number} pixelValue - The value in pixels to be converted.
 * @returns {number} - The value in rem units.
 */
export const convertPixelToRem = (pixelValue: number) => {
	const baseFontSize = 16;
	return pixelValue / baseFontSize;
};

export const generateUniqueClassName = (str: string) => {
	const className = str
		.replace(/[^a-zA-Z0-9-_ ]/g, '') // Remove special characters except for _ and -
		.replace(/\s+/g, '-') // Replace spaces with hyphens
		.trim();
	return `class-${className}`;
};
