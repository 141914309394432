import { ActionTypes } from 'Actions/actions';
import type { TResponse } from 'ReduxTypes/travelerMedia';

const initialState = {
	byTgIdAndFilter: {},
	status: { isFetching: { byTgIdAndFilter: {} } },
};

export const travelerMediaStore = (
	state = initialState,
	action: {
		type?: string;
		payload: {
			tgId?: string;
			travelerMedia?: TResponse;
		};
	},
) => {
	switch (action.type) {
		case ActionTypes.REQUEST_TRAVELER_MEDIA: {
			const { tgId } = action.payload;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byTgIdAndFilter: {
							...state.status.isFetching.byTgIdAndFilter,
							[tgId!]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_TRAVELER_MEDIA_BY_TGID: {
			const { travelerMedia, tgId } = action.payload;
			if (!travelerMedia) return;
			let { items, total, nextOffset } = travelerMedia;
			nextOffset ??= total;

			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byTgIdAndFilter: {
							...state.status.isFetching.byTgIdAndFilter,
							[tgId!]: false,
						},
					},
				},
				byTgIdAndFilter: {
					...state?.byTgIdAndFilter,
					[tgId!]: {
						reviews: items,
						total,
						nextOffset,
					},
				},
			};
		}

		case ActionTypes.APPEND_TRAVELER_MEDIA_BY_TGID: {
			const { travelerMedia, tgId } = action.payload;
			if (!travelerMedia) return;
			let { items, total, nextOffset = total } = travelerMedia;
			const currentReviews = [];
			const reviews = (state.byTgIdAndFilter as any)[tgId!]?.reviews;
			const currentOffset =
				(state.byTgIdAndFilter as any)[tgId!]?.nextOffset ?? 0;
			nextOffset ??= total;
			const isNext = nextOffset > currentOffset;

			if (reviews)
				currentReviews.push(
					...(state.byTgIdAndFilter as any)[tgId!].reviews,
				);
			if (isNext) {
				/**
				 * doing this because of double API call on product page
				 * THIS SHOULD BE REMOVED.
				 */
				currentReviews.push(...items);
			}
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byTgIdAndFilter: {
							...state.status.isFetching.byTgIdAndFilter,
							[tgId!]: false,
						},
					},
				},
				byTgIdAndFilter: {
					...state?.byTgIdAndFilter,
					[tgId!]: {
						reviews: currentReviews,
						total,
						nextOffset: Math.max(nextOffset, currentOffset),
					},
				},
			};
		}
		default:
			return state;
	}
};
