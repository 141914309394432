import { ActionTypes } from 'Actions/actions';
import type { TCheckoutMetadataActionTypes } from 'ReduxTypes/checkoutMetadata';

export const requestCheckoutMetadata = ({
	id,
}: any): TCheckoutMetadataActionTypes => ({
	type: ActionTypes.REQUEST_CHECKOUT_METADATA,
	payload: { id },
});

export const receiveCheckoutMetadata = ({
	id,
	payload,
}: any): TCheckoutMetadataActionTypes => ({
	type: ActionTypes.RECEIVE_CHECKOUT_METADATA,
	payload: { id, checkoutMetadata: payload },
});
