import type { ReactNode } from 'react';
import React from 'react';

const Conditional: React.FC<
	React.PropsWithChildren<{ if: any; children: ReactNode }>
> = props => (
	<React.Fragment>{props.if ? props.children : null}</React.Fragment>
);

export default Conditional;
