/* eslint-disable no-control-regex */
import type {
	MVoucher,
	TLanguage,
} from 'Components/common/vouchers/voucher/interface';
import {
	InventoryType,
	TicketType,
	TicketUIType,
} from 'Components/common/vouchers/voucher/interface';

import dayjs from './dayjsUtil';

const getGrammarCorrectedPaxString = (paxDetail: MVoucher.IPaxDetail) => {
	if (paxDetail.paxCount > 1) {
		return `${paxDetail.paxCount} ${paxDetail.pluralDisplayName}`;
	}

	return `${paxDetail.paxCount} ${paxDetail.displayName}`;
};

export const getPaxDetailString = (paxDetails: Array<MVoucher.IPaxDetail>) => {
	let paxDetailString = '';

	paxDetails?.map((item, index) => {
		if (index === 0) {
			paxDetailString = `${getGrammarCorrectedPaxString(item)}`;
		} else
			paxDetailString = `${paxDetailString}, ${getGrammarCorrectedPaxString(
				item,
			)}`;
	});

	return paxDetailString;
};

export const getSeatmapDetailString = (
	seatmapDetails: Array<MVoucher.ISeatmapDetails>,
) => {
	if (!seatmapDetails) return null;

	let seatmapDetailString = '';

	seatmapDetails.forEach(seat => {
		if (seatmapDetailString.length === 0) {
			seatmapDetailString = `${seat.sectionName} - `;
		} else {
			seatmapDetailString = `${seatmapDetailString}\n${seat.sectionName} - `;
		}
		let seatDetailString = '';
		seat.seatDetails.forEach(seatDetail => {
			if (seatDetailString.length === 0)
				seatDetailString = `${seatDetail.row}${seatDetail.seatNumber}`;
			else
				seatDetailString += `, ${seatDetail.row}${seatDetail.seatNumber}`;
		});
		seatmapDetailString = `${seatmapDetailString}${seatDetailString}`;
	});

	return seatmapDetailString;
};

export const timeConversion = (milliseconds: number) => {
	const hours = Math.floor(dayjs.duration(milliseconds).asHours());
	const min = parseInt(dayjs.utc(milliseconds).format('mm'));
	return `${hours ? `${hours} ${hours === 1 ? 'hour' : 'hours'}` : ''} ${
		min ? `${min} min` : ''
	}`;
};

export const getTicket = (tickets: any) => {
	const isMultiTicketType = (tickets: Array<MVoucher.ITicket>): boolean => {
		if (!tickets) return false;

		const ticketTypes = new Set();
		for (const ticket of tickets) {
			ticketTypes.add(ticket.ticketType);
			if (ticketTypes.size > 1) {
				return true;
			}
		}
		return false;
	};

	const multipleTickets =
		tickets && tickets?.length > 1 && isMultiTicketType(tickets);

	const ticketType = {
		qr: tickets?.filter(
			(ticket: any) => ticket.ticketType === TicketType.QRCODE,
		),
		barcode: tickets?.filter(
			(ticket: any) => ticket.ticketType === TicketType.BARCODE,
		),
		text: tickets?.filter(
			(ticket: any) => ticket.ticketType === TicketType.TEXT,
		),
		pdf: tickets?.filter(
			(ticket: any) =>
				ticket.ticketType === TicketType.PDF ||
				ticket.ticketType === TicketType.HTML,
		),
	};
	return { ticketType, multipleTickets };
};

export const checkIfWalletTypeTickets = (tickets: MVoucher.ITicket[]) =>
	tickets?.some(ticket => ticket?.ticketUIType === TicketUIType.WALLET_PASS);

export const getQueryString = (query: Record<string, any>): string => {
	const {
		ticketType,
		count,
		lang,
		date,
		time,
		spRef,
		seats,
		duration,
		expiry,
	} = query;

	let searchObject = new URLSearchParams();
	if (ticketType) searchObject.set('ticketType', ticketType);
	if (count) searchObject.set('count', count);
	if (lang) searchObject.set('lang', lang.toUpperCase());
	if (date) searchObject.set('date', date);
	if (time) searchObject.set('time', time);
	if (spRef) searchObject.set('spRef', spRef);
	if (seats) searchObject.set('seats', seats);
	if (duration) searchObject.set('duration', duration);
	if (expiry) searchObject.set('expiry', expiry);
	return searchObject.toString();
};

const getIndividualPaxDetails = (paxDetails: any, index: any) => {
	// @ts-expect-error TS(7034): Variable 'paxIndividualDetails' implicitly has typ... Remove this comment to see the full error message
	let paxIndividualDetails = [];
	paxDetails.forEach((pax: any) => {
		let details = {
			paxString: `1 ${pax.displayName}`,
			paxPrice: pax.paxPrice,
		};
		for (let i = 0; i < pax.paxCount; i++) {
			paxIndividualDetails.push(details);
		}
	});
	// @ts-expect-error TS(7005): Variable 'paxIndividualDetails' implicitly has an ... Remove this comment to see the full error message
	return paxIndividualDetails[index];
};

export const getVoucherBookingList = (bookingDetails: any) => {
	const { tickets, paxDetails } = bookingDetails;
	const bookingDetailsList = tickets?.map((ticket: any, idx: any) => {
		let bookingData = { ...bookingDetails };
		bookingData.tickets = [ticket];
		bookingData.paxIndividualDetails = getIndividualPaxDetails(
			paxDetails,
			idx,
		);
		return bookingData;
	});
	return bookingDetailsList;
};

export const getParsedHtml = (html: string): string => {
	const redemptionNewFormatFlag = html.includes('contentV2');

	if (redemptionNewFormatFlag) return html;

	const newLineRegex = new RegExp('\n*', 'g');
	const newLineHTML = html.replaceAll(newLineRegex, '');
	const brRegex = new RegExp('([\t ]*<br>[\t ]*)+', 'g');
	const brFixedHTML = newLineHTML.replaceAll(brRegex, '<br>');
	const pbrRegex = new RegExp('</p>[\t ]*<br>', 'g');
	const pbrFixedHTML = brFixedHTML.replaceAll(pbrRegex, '</p>');

	const hyphenRegex = new RegExp('-[\t ]+(.+?)<br>', 'g');
	const bulletFixedHTML = pbrFixedHTML.replaceAll(
		hyphenRegex,
		'<ul><li>$1</li></ul>',
	);

	const pbrStrongRegex = new RegExp('<p>[\t ]*<br>[\t ]*<strong>', 'g');
	const headingFixedHTML = bulletFixedHTML.replaceAll(
		pbrStrongRegex,
		'<p><strong>',
	);
	return headingFixedHTML;
};

export const getIsDurationFixed = (tourInventoryType: string) => {
	return (
		tourInventoryType === InventoryType.FIXED_START_FIXED_DURATION ||
		tourInventoryType === InventoryType.FLEXIBLE_START_FIXED_DURATION
	);
};

export const getValidity = (validity: string, language: TLanguage): string => {
	// @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
	if (!validity) return undefined;
	const skipParsing =
		dayjs(validity).format('MMM DD, YYYY') === 'Invalid Date' &&
		language !== 'EN';
	if (skipParsing) return validity;
	return dayjs(validity).format('MMM DD, YYYY');
};
