import type {
	TAirportTransfersSearchFormState,
	TSearchFormStep,
	TTransferType,
} from 'Reducers/airportTransfersBookingDataStore';

export const MWEB_PREV_STEP = 'MWEB_PREV_STEP';
export const MWEB_NEXT_STEP = 'MWEB_NEXT_STEP';
export const SET_MWEB_CURRENT_FORM_STEP = 'SET_MWEB_CURRENT_FORM_STEP';
export const SET_AIRPORT_DATA = 'SET_AIRPORT_DATA';
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA';
export const SET_PAX_AND_LUGGAGE = 'SET_PAX_AND_LUGGAGE';
export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
export const SET_PICKUP_TIME = 'SET_PICKUP_TIME';
export const SET_STATE = 'SET_STATE';
export const SET_TRANSFER_TYPE = 'SET_TRANSFER_TYPE';
export const SET_AIRPORTS_LIST = 'SET_AIRPORTS_LIST';

type TNextStepAction = { type: typeof MWEB_NEXT_STEP };
type TPrevStepAction = { type: typeof MWEB_PREV_STEP };
type SetCurrentFormStepAction = {
	type: typeof SET_MWEB_CURRENT_FORM_STEP;
	payload: TSearchFormStep;
};
type SetAirportDataAction = {
	type: typeof SET_AIRPORT_DATA;
	payload: TAirportTransfersSearchFormState['airportData'];
};
type SetLocationDataAction = {
	type: typeof SET_LOCATION_DATA;
	payload: Partial<TAirportTransfersSearchFormState['locationData']>;
};
type SetPaxAndLuggageAction = {
	type: typeof SET_PAX_AND_LUGGAGE;
	payload: Partial<TAirportTransfersSearchFormState['paxAndLuggage']>;
};
type SetPickupDateAction = { type: typeof SET_PICKUP_DATE; payload: string };
type SetPickupTimeAction = { type: typeof SET_PICKUP_TIME; payload: string };
type SetStateAction = {
	type: typeof SET_STATE;
	payload: Partial<TAirportTransfersSearchFormState>;
};

type SetTransferTypeAction = {
	type: typeof SET_TRANSFER_TYPE;
	payload: TTransferType;
};

type SetAirportListAction = {
	type: typeof SET_AIRPORTS_LIST;
	payload: any;
};

export type TAirportTransfersSearchActions =
	| TNextStepAction
	| TPrevStepAction
	| SetCurrentFormStepAction
	| SetAirportDataAction
	| SetLocationDataAction
	| SetPaxAndLuggageAction
	| SetPickupDateAction
	| SetPickupTimeAction
	| SetStateAction
	| SetTransferTypeAction
	| SetAirportListAction;

export function searchFormNextStep(): TNextStepAction {
	return { type: MWEB_NEXT_STEP };
}

export function searchFormPrevStep(): TPrevStepAction {
	return { type: MWEB_PREV_STEP };
}

export function setCurrentFormStep(
	formStep: TSearchFormStep,
): SetCurrentFormStepAction {
	return { type: SET_MWEB_CURRENT_FORM_STEP, payload: formStep };
}

export function setAirportData(
	address: TAirportTransfersSearchFormState['airportData'],
): SetAirportDataAction {
	return { type: SET_AIRPORT_DATA, payload: address };
}

export function setLocationData(
	address: Partial<TAirportTransfersSearchFormState['locationData']>,
): SetLocationDataAction {
	return { type: SET_LOCATION_DATA, payload: address };
}

export function setPaxAndLuggage(
	paxAndLuggage: Partial<TAirportTransfersSearchFormState['paxAndLuggage']>,
): SetPaxAndLuggageAction {
	return { type: SET_PAX_AND_LUGGAGE, payload: paxAndLuggage };
}

export function setPickupDate(date: string): SetPickupDateAction {
	return { type: SET_PICKUP_DATE, payload: date };
}

export function setPickupTime(time: string): SetPickupTimeAction {
	return { type: SET_PICKUP_TIME, payload: time };
}

export function setAirportTranferSearchFormState(
	newState: Partial<TAirportTransfersSearchFormState>,
): SetStateAction {
	return { type: SET_STATE, payload: newState };
}

export const setTransferType = (
	transferType: TTransferType,
): SetTransferTypeAction => ({
	type: SET_TRANSFER_TYPE,
	payload: transferType,
});

export const setAirportsList = (airportsList: any): SetAirportListAction => ({
	type: SET_AIRPORTS_LIST,
	payload: airportsList,
});
