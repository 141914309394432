import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Conditional from 'Components/common/conditional';
import Image from 'Components/common/image';
import LinkWrapper from 'Components/common/linkWrapper';

import { PoweredByHeadout } from 'Assets/svg/header/PoweredByHeadout';

import { isWhitelabel } from 'Utils/hostUtils';
import PlatformUtils from 'Utils/platformUtils';
import { getCurrentPage, getDomainLogoConfig, getHost } from 'Utils/stateUtils';

import { LogoWrapper } from './__style';
import type { ILogoSection, IMapStateToProps } from './interface';

const LOGO_DIMENSIONS = {
	MOBILE: {
		width: 112.5,
		height: 18,
	},
	DESKTOP: {
		width: 153,
		height: 24,
	},
};

const LogoSection: React.FC<
	React.PropsWithChildren<ILogoSection & IMapStateToProps>
> = ({
	logo,
	host,
	clickUrl,
	linkProps,
	layout = 'fill',
	forceFullyWhitewash = false,
}) => {
	const isWhitelabelHost = isWhitelabel({ host });

	const {
		logoUrl,
		showLogo = true,
		redirectUrl,
		showPoweredLogo,
		invertLogo,
	} = logo;
	const linkUrl = redirectUrl || clickUrl;

	if (!showLogo || !logoUrl) return null;

	const logoClassNames = classNames('logo-image-wrapper', {
		whitewash: forceFullyWhitewash || invertLogo,
		'whitelabel-logo': isWhitelabelHost,
		'with-powered-by': showPoweredLogo,
	});

	const logoDimensions = !isWhitelabelHost
		? PlatformUtils.isDesktop()
			? LOGO_DIMENSIONS.DESKTOP
			: LOGO_DIMENSIONS.MOBILE
		: {};

	return (
		<LinkWrapper
			className='logo-wrapper'
			url={linkUrl}
			href={linkUrl}
			linkProps={linkProps}
		>
			<LogoWrapper className='main-logo-wrapper'>
				<Image
					src={logoUrl}
					alt='Headout logo. Link to home.'
					className={logoClassNames}
					useImg={isWhitelabelHost}
					priority
					layout={layout}
					{...logoDimensions}
				/>
				<Conditional if={showPoweredLogo}>
					<PoweredByHeadout className='powered-by-headout' />
				</Conditional>
			</LogoWrapper>
		</LinkWrapper>
	);
};

const mapStateToProps = (state: any): IMapStateToProps => ({
	host: getHost(state),
	currentPage: getCurrentPage(state),
	logo: getDomainLogoConfig(state),
});

const Logo = connect(mapStateToProps, null)(LogoSection);

export default Logo;
