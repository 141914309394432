import { ActionTypes } from 'Actions/actions';

import type { PROMOTION_TYPES } from 'Constants/constants';

export type TPromotionKeys = keyof typeof PROMOTION_TYPES;
export type TPromotionTypes = (typeof PROMOTION_TYPES)[TPromotionKeys];

const initialState: Record<TPromotionTypes, any> | {} = {};

export const promoStore = (
	state = initialState,
	action: { type: string; payload: Record<TPromotionTypes, any> },
) => {
	switch (action.type) {
		case ActionTypes.SET_PROMO_DETAILS:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
