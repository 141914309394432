import { ActionTypes } from 'Actions/actions';
import type {
	CategoriesStoreType,
	TStarredCategoryOrSubCategory,
} from 'ReduxTypes/categories';

const initialState: CategoriesStoreType = {
	byCityCode: {},
	categoriesByCityCode: {},
	subCategoriesByCityCode: {},
	sections: {},
	status: {},
	starredCategoriesAndSubCategoriesByCityCode: {},
	subCategoriesFilterDataByCategoryId: {},
	subCategoriesFilterDataByCategoryIdWithCollectionFilter: {},
};

export const categoriesStore = (state = initialState, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_CATEGORIES_AND_SUBCATEGORIES: {
			const { cityCode } = action;
			return {
				...state,
				status: {
					...state.status,
					[cityCode]: true,
				},
			};
		}
		case ActionTypes.RECEIVE_CATEGORIES_AND_SUBCATEGORIES: {
			const { cityCode, data } = action;
			const { categories, starredCategoriesAndSubCategories } = data;
			let updatedCategories = {};
			let subCategoriesInfo = {};
			let categoryIdsByCityCode: Array<number> = [];
			const starredCategoriesAndSubCategoriesForCity: Array<TStarredCategoryOrSubCategory> =
				[];
			categories.forEach((category: any) => {
				const { id, subCategories, ...otherProperties } = category;
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				updatedCategories[id] = {
					id,
					// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
					subCategories: subCategories.map(({ id }) => id),
					...otherProperties,
				};
				subCategories.forEach((subCategory: any) => {
					const { id } = subCategory;
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					subCategoriesInfo[id] = {
						...subCategory,
					};
				});
				categoryIdsByCityCode.push(id);
			});
			starredCategoriesAndSubCategories.forEach((item: any) => {
				const { categoryId, subCategoryId, rank } = item;
				starredCategoriesAndSubCategoriesForCity.push({
					id: categoryId ?? subCategoryId,
					isCategory: !!categoryId,
					rank,
				});
			});
			return {
				...state,
				byCityCode: {
					...state.byCityCode,
					[cityCode]: categoryIdsByCityCode,
				},
				subCategoriesByCityCode: {
					...state.subCategoriesByCityCode,
					[cityCode]: subCategoriesInfo,
				},
				categoriesByCityCode: {
					...state.categoriesByCityCode,
					[cityCode]: updatedCategories,
				},
				starredCategoriesAndSubCategoriesByCityCode: {
					...state.starredCategoriesAndSubCategoriesByCityCode,
					[cityCode]: starredCategoriesAndSubCategoriesForCity,
				},
			};
		}
		case ActionTypes.RECEIVE_CATEGORY_SECTIONS: {
			const { payload } = action;
			const {
				sections,
				city: { cityCode },
			} = payload;
			return {
				...state,
				sections: {
					...state.sections,
					[cityCode]: sections,
				},
			};
		}
		case ActionTypes.RECEIVE_SUBCATEGORY_FILTERS_BY_CATEGORY: {
			const {
				payload: { subCategories, categoryId, useCollectionFilter },
			} = action;

			const reduxKey = useCollectionFilter
				? 'subCategoriesFilterDataByCategoryIdWithCollectionFilter'
				: 'subCategoriesFilterDataByCategoryId';

			return {
				...state,
				[reduxKey]: {
					...state[reduxKey],
					[categoryId]: subCategories,
				},
			};
		}
		default:
			return state;
	}
};
