import type {
	MediaType,
	TMedia,
	TMicroBrandInfo,
	TPOIInfo,
	TRatingsInfo,
} from 'ReduxTypes/generics';

export type Video = TMedia<MediaType.VIDEO>;

export type Videos = Array<Video>;

export type TCollection = {
	id: number;
	name: string;
	displayName: string;
	cityCode: string;
	cityDisplayName: string;
	urlSlug: string;
	heroImageUrl: string;
	cardImageUrl: string;
	title: string;
	heading: string;
	subtext: string;
	longFormDescription: any;
	metaDescription: string;
	primaryParentId: number;
	super: boolean;
	tags: string[];
	parentIds: number[];
	childrenIds: number[];
	canonicalUrl: any;
	noIndex: boolean;
	supportedLanguages: string[];
	language: string;
	urlSlugs: Record<string, string>;
	startingPrice: TCategoryStartingPrice;
	parentCollections: any;
	active: boolean;
	ratingsInfo: TRatingsInfo;
	collectionVideo: any;
	videos: TMedia<MediaType.VIDEO>[];
	poiId?: number;
	poiInfo?: TPOIInfo;
	heroMedia: TMedia;
	cardMedia: TMedia<MediaType.IMAGE>;
	secondaryCities: any[];
	microBrandInfo: TMicroBrandInfo;
	useOldDesign: boolean;
	personaAffinityTags: { id: string; name: string }[];
	durationInMinutes: number;
	apiError?: {
		statusCode: number;
	};
};
export type TCollectionCardV2 = {
	id: number;
	displayName: string;
	primaryCity: {
		code: string;
		displayName: string;
	};
	language: string;
	medias: [
		{
			url: string;
			type: 'IMAGE';
			metadata: {
				altText: null | string;
				videoDuration: null;
				uploadDate: string;
			};
		},
	];
	listingPrice: {
		listingPrice: number;
		currency: string;
		tourGroupId: number;
	};
	ratings: {
		count: number;
		value: number;
	};
	urlSlug: string;
};

export type TCategoryStartingPrice = {
	listingPrice: number;
	currency: string;
	tourGroupId: number;
};

export type TCollectionBasics = Pick<
	TCollection,
	| 'apiError'
	| 'id'
	| 'urlSlugs'
	| 'secondaryCities'
	| 'supportedLanguages'
	| 'useOldDesign'
> & { primaryCityCode: string; type: ECollectionType };

export enum ECollectionType {
	GENERIC = 'GENERIC',
	POI = 'POI',
	DAY_TRIP = 'DAY_TRIP',
}
