import { strings } from 'Constants/strings';

import { isOnDemandEnv } from './envUtils';

export const UNKNOWN = strings.PAYMENT_FAILURE_ERROR_MESSAGES?.['PAY-0700'];

const REQUEST_TIMED_OUT = strings.REQUEST_TIMEOUT_TRY_AGAIN;

const makeError = ({
	// @ts-expect-error TS(7031): Binding element 'response' implicitly has an 'any'... Remove this comment to see the full error message
	response,
	// @ts-expect-error TS(7031): Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
	message,
	code = null,
	// @ts-expect-error TS(7031): Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
	title,
	// @ts-expect-error TS(7031): Binding element 'localisedMessage' implicitly has ... Remove this comment to see the full error message
	localisedMessage,
}) => {
	const error = new Error(message);
	(error as any).url = response.url;
	(error as any).status = response.status;
	(error as any).code = code || `STATUS-${response.status}`;
	error.message = message || UNKNOWN;
	(error as any).localisedMessage = localisedMessage;
	(error as any).title = title;
	return error;
};

const resolveResponse = (response: any) => {
	const { status } = response;
	if (response.ok) {
		return Promise.resolve(response);
	}
	if (
		response.url.includes(process.env.NEXT_PUBLIC_HEADOUT_ROOT_DOMAIN) ||
		response.url.includes('book.') ||
		isOnDemandEnv()
	) {
		if (status === 404) {
			return Promise.reject(
				// @ts-expect-error TS(2345): Argument of type '{ response: any; message: string... Remove this comment to see the full error message
				makeError({ response, message: 'Not Found' }),
			);
		} else if (status === 504) {
			return Promise.reject(
				// @ts-expect-error TS(2345): Argument of type '{ response: any; message: any; }... Remove this comment to see the full error message
				makeError({ response, message: REQUEST_TIMED_OUT }),
			);
		}
		return response
			.json()
			.catch(() =>
				// @ts-expect-error TS(2345): Argument of type '{ response: any; message: string... Remove this comment to see the full error message
				Promise.reject(makeError({ response, message: UNKNOWN })),
			)
			.then((errJson: any) => {
				const { error } = errJson;
				return Promise.reject(makeError({ response, ...error }));
			});
	}

	let message: string = UNKNOWN;
	response
		.json()
		.then((json: any) => {
			if (typeof json === 'object' && json !== null && 'error' in json) {
				message =
					(json.error?.localisedMessage as string) ||
					(json.error?.message as string);
			}
		})
		.catch(() => {
			message = UNKNOWN;
		});
	// @ts-expect-error TS(2345): Argument of type '{ response: any; message: string... Remove this comment to see the full error message
	return Promise.reject(makeError({ response, message: message }));
};

export default resolveResponse;
