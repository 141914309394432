import PlatformUtils from 'Utils/platformUtils';

import type {
	setActiveProductCard,
	updateActiveProductCardQueue,
} from 'Actions/productCard';
import { PRODUCT_CARD_ACTION_TYPES } from 'Actions/productCard';

export type State = {
	activeProductCard: string | null;
	activeProductCardQueue: string[];
};

export const initialState: State = {
	activeProductCard: null,
	activeProductCardQueue: [],
};

export const getElementFromUniqueId = (id: string | null) => {
	if (!id) return null;
	return document?.querySelector(`[data-unique-id="${id}"]`);
};

const getCardPositionFromTop = (cardElement: Element | null) => {
	const { top } = cardElement?.getBoundingClientRect() || {
		top: Number.MAX_SAFE_INTEGER,
	};
	return top;
};

const sortComparefn = (a: string, b: string) =>
	getCardPositionFromTop(getElementFromUniqueId(a)) -
	getCardPositionFromTop(getElementFromUniqueId(b));

const isDesktop = PlatformUtils.isDesktop();

export type ProductCardAction =
	| ReturnType<typeof setActiveProductCard>
	| ReturnType<typeof updateActiveProductCardQueue>;

export const productCardReducer = (
	state = initialState,
	action: ProductCardAction,
): State => {
	switch (action.type) {
		case PRODUCT_CARD_ACTION_TYPES.SET_ACTIVE_PRODUCT_CARD: {
			const { productCardUniqueId: latestProductCardIdentifier } =
				action.payload;
			const {
				activeProductCard: currentActiveProductCardIdentifier,
				activeProductCardQueue: currentActiveProductCardQueue,
			} = state;

			const latestProductCard = getElementFromUniqueId(
				latestProductCardIdentifier,
			);
			const currentActiveProductCard = getElementFromUniqueId(
				currentActiveProductCardIdentifier,
			);

			const latestProductCardInViewTop =
				getCardPositionFromTop(latestProductCard);
			const currentActiveProductCardTop = getCardPositionFromTop(
				currentActiveProductCard,
			);

			switch (true) {
				/**
				 * For desktop, hovered card should be the active one
				 */
				case isDesktop: {
					return {
						...state,
						activeProductCard: latestProductCardIdentifier,
					};
				}

				/**
				 * 1. If no current active product card
				 * 2.a. If no card in queue
				 * 2.b. If latest card is above the first card in queue
				 */
				case !currentActiveProductCard &&
					(currentActiveProductCardQueue.length === 0 ||
						latestProductCardInViewTop <
							getCardPositionFromTop(
								getElementFromUniqueId(
									currentActiveProductCardQueue?.[0],
								),
							)): {
					return {
						...state,
						activeProductCard: latestProductCardIdentifier,
					};
				}

				/**
				 * 1. If latest card in view is null
				 * 2. If there are cards in queue
				 * 3. If current active product card goes out of view (top)
				 */
				case !latestProductCard &&
					currentActiveProductCardQueue.length > 0 &&
					currentActiveProductCardTop < 0: {
					return {
						...state,
						activeProductCard: currentActiveProductCardQueue[0],
						activeProductCardQueue:
							currentActiveProductCardQueue.slice(1),
					};
				}

				/**
				 * 1. If latest card in view is null (happens when current active product card goes out of view)
				 * 2. If there are no cards in queue
				 */
				case !latestProductCard: {
					return {
						...state,
						activeProductCard: null,
					};
				}

				/**
				 * 1. If the latest card in view is below the current active product card
				 */
				case currentActiveProductCardTop < latestProductCardInViewTop: {
					return {
						...state,
						activeProductCardQueue: [
							...currentActiveProductCardQueue,
							latestProductCardIdentifier!,
						].sort(sortComparefn),
					};
				}

				/**
				 * 1. If the latest card in view is above the current active product card
				 */
				case currentActiveProductCardTop > latestProductCardInViewTop: {
					return {
						...state,
						activeProductCard: latestProductCardIdentifier,
						activeProductCardQueue: [
							...currentActiveProductCardQueue,
							currentActiveProductCardIdentifier as string,
						].sort(sortComparefn),
					};
				}

				default: {
					return {
						...state,
					};
				}
			}
		}

		case PRODUCT_CARD_ACTION_TYPES.UPDATE_ACTIVE_PRODUCT_CARD_QUEUE: {
			const { productCardUniqueId: prevCardInViewIdentifier = null } =
				action.payload;
			const { activeProductCardQueue } = state;

			return {
				...state,
				activeProductCardQueue: activeProductCardQueue.filter(
					productCardIdentifier =>
						productCardIdentifier !== prevCardInViewIdentifier,
				),
			};
		}

		default: {
			return state;
		}
	}
};
