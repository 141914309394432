import type { MCartStore } from 'Utils/crossSell/types';

import type {
	initializeCartSelectionStoreItem,
	setCartItemTourId,
	setCartRecommendationsData,
	setCartSelectionItem,
	setCartSelectionItemDate,
	setCartSelectionItemPax,
	setCartSelectionItemTime,
	setCartSelectionItemVariantId,
} from 'Actions/cart';
import { CartActions } from 'Actions/cart';

const DEFAULT_CART_SELECTION_VALUES: MCartStore.ISingularCartSelection = {
	id: null,
	instantCheckout: false,
	isDefaultDate: false,
	isDefaultTime: false,
	isDefaultTour: false,
	isDefaultTourId: false,
	isDefaultVariant: false,
	language: 'en',
	selectedDate: null,
	selectedTime: null,
	selectedTourId: null,
	selectedVariantId: null,
	selectionMap: {},
	groupSize: 0,
	userFields: [],
};

const cartReducerInitialState: MCartStore.ICartGlobalState = {
	isCartRecommendationsFetching: true,
	cartRecommendations: null,
	cartSelections: {},
	cartSelectionsOrderTourGroupIds: [],
};

type TCartAction =
	| ReturnType<typeof setCartRecommendationsData>
	| ReturnType<typeof initializeCartSelectionStoreItem>
	| ReturnType<typeof setCartSelectionItem>
	| ReturnType<typeof setCartSelectionItemDate>
	| ReturnType<typeof setCartSelectionItemTime>
	| ReturnType<typeof setCartSelectionItemVariantId>
	| ReturnType<typeof setCartSelectionItemPax>
	| ReturnType<typeof setCartItemTourId>;

/**
 * Cart Reducer
 */
export const cartReducer = (
	state: MCartStore.ICartGlobalState = cartReducerInitialState,
	action: TCartAction,
) => {
	const { payload, type } = action;
	switch (type) {
		case CartActions.SET_CART_RECOMMENDATIONS_DATA: {
			const { cartRecommendations } = payload;
			return {
				...state,
				cartRecommendations,
			};
		}
		case CartActions.SET_CART_ITEM_INITIALIZATION: {
			const { id } = payload;
			return {
				...state,
				cartSelections: {
					...state.cartSelections,
					[String(id)]: {
						...DEFAULT_CART_SELECTION_VALUES,
						id: Number(id),
					},
				},
			};
		}
		case CartActions.SET_CART_SELECTION_ITEM: {
			/* destructing to accept only required keys */
			const {
				id,
				selectedDate,
				selectedTime,
				selectionMap,
				selectedVariantId,
				userFields,
				language,
				instantCheckout,
				isDefaultDate,
				isDefaultTour,
				isDefaultVariant,
				groupSize,
			} = payload;

			return {
				...state,
				cartSelections: {
					...state.cartSelections,
					[String(id)]: {
						...DEFAULT_CART_SELECTION_VALUES,
						id,
						selectedDate,
						selectedTime,
						selectionMap,
						selectedVariantId,
						userFields,
						language,
						instantCheckout,
						isDefaultDate,
						isDefaultTour,
						isDefaultVariant,
						groupSize,
					},
				},
			};
		}
		case CartActions.SET_CART_ITEM_BOOKING_DATE: {
			const { id, date, defaultSelection } = payload;
			let cartItem = state?.cartSelections?.[String(id)];
			if (cartItem) {
				cartItem = {
					...cartItem,
					selectedDate: date,
					isDefaultDate: Boolean(defaultSelection),
				};
				if (!date) {
					cartItem = {
						...cartItem,
						selectedTime: null,
					};
				}
			}
			return {
				...state,
				cartSelections: {
					...state.cartSelections,
					[String(id)]: cartItem,
				},
			};
		}
		case CartActions.SET_CART_ITEM_BOOKING_TIME: {
			const { id, time, defaultSelection } = payload;
			let cartItem = state?.cartSelections?.[String(id)];
			if (cartItem) {
				cartItem = {
					...cartItem,
					selectedTime: time,
					isDefaultTime: Boolean(defaultSelection),
				};
			}
			return {
				...state,
				cartSelections: {
					...state.cartSelections,
					[String(id)]: cartItem,
				},
			};
		}
		case CartActions.SET_CART_ITEM_VARIANT_ID: {
			const { id, variantId, defaultSelection } = payload;
			let cartItem = state?.cartSelections?.[String(id)];
			if (cartItem) {
				cartItem = {
					...cartItem,
					selectedVariantId: variantId,
					isDefaultVariant: Boolean(defaultSelection),
				};
			}
			return {
				...state,
				cartSelections: {
					...state.cartSelections,
					[String(id)]: cartItem,
				},
			};
		}
		case CartActions.SET_CART_ITEM_TOUR_ID: {
			const { id, tourId, defaultSelection } = payload;
			let cartItem = state?.cartSelections?.[String(id)];
			if (cartItem) {
				cartItem = {
					...cartItem,
					selectedTourId: tourId,
					isDefaultTourId: Boolean(defaultSelection),
				};
			}
			return {
				...state,
				cartSelections: {
					...state.cartSelections,
					[String(id)]: cartItem,
				},
			};
		}
		case CartActions.SET_CART_ITEM_BOOKING_PEOPLE: {
			const { id, pricing, selectionMap = {}, groupSize = 0 } = payload;
			let cartItem = state?.cartSelections?.[String(id)];
			if (cartItem) {
				cartItem = {
					...cartItem,
					selectionMap,
					groupSize,
				};
			}

			return {
				...state,
				cartSelections: {
					...state.cartSelections,
					[String(id)]: cartItem,
				},
			};
		}
		default: {
			return state;
		}
	}
};
