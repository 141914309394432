import type {
	receiveBatchPOIInfoById,
	receivePOIInfoByCollectionId,
	receivePOIInfoById,
	receivePOIInfoByTgId,
	requestBatchPOIInfoById,
	requestPOIInfoByCollectionId,
	requestPOIInfoById,
	requestPOIInfoByTgId,
} from 'Actions/poi';
import { POIActions } from 'Actions/poi';
import type { TPoi, TReduxState } from 'ReduxTypes/poi';

const initialState: TReduxState = {
	byId: {},
	byCollectionId: {},
	byTgId: {},
	isFetching: { byId: {}, byTgId: {}, byCollectionId: {} },
};

export const poiStore = (
	state = initialState,
	{
		payload,
		type,
	}:
		| ReturnType<typeof requestPOIInfoById>
		| ReturnType<typeof requestBatchPOIInfoById>
		| ReturnType<typeof receivePOIInfoById>
		| ReturnType<typeof requestPOIInfoByTgId>
		| ReturnType<typeof receivePOIInfoByTgId>
		| ReturnType<typeof requestPOIInfoByCollectionId>
		| ReturnType<typeof receivePOIInfoByCollectionId>
		| ReturnType<typeof receiveBatchPOIInfoById>,
): TReduxState => {
	switch (type) {
		case POIActions.REQUEST_POI_INFO_BY_ID: {
			const { id } = payload;
			return {
				...state,
				isFetching: {
					...state.isFetching,
					byId: {
						...state.isFetching.byId,
						[id]: true,
					},
				},
			};
		}

		case POIActions.REQUEST_BATCH_POI_INFO_BY_ID: {
			const { ids } = payload;
			const fetchingPayload = ids?.reduce((payload, id) => {
				return {
					...payload,
					[id]: true,
				};
			}, {});

			return {
				...state,
				isFetching: {
					...state.isFetching,
					byId: {
						...state.isFetching.byId,
						...fetchingPayload,
					},
				},
			};
		}

		case POIActions.REQUEST_POI_INFO_BY_TGID: {
			const { tgid } = payload;
			return {
				...state,
				isFetching: {
					...state.isFetching,
					byTgId: {
						...state.isFetching.byTgId,
						[tgid]: true,
					},
				},
			};
		}

		case POIActions.REQUEST_POI_INFO_BY_COLLECTION_ID: {
			const { collectionId } = payload;
			return {
				...state,
				isFetching: {
					...state.isFetching,
					byCollectionId: {
						...state.isFetching.byCollectionId,
						[collectionId]: true,
					},
				},
			};
		}

		case POIActions.RECEIVE_POI_INFO_BY_ID: {
			const { id, response } = payload;
			if (!response?.poi) return state;

			return {
				...state,
				byId: {
					...state.byId,
					[id]: response.poi,
				},
				isFetching: {
					...state.isFetching,
					byId: {
						...state.isFetching.byId,
						[id]: false,
					},
				},
			};
		}

		case POIActions.RECEIVE_BATCH_POI_INFO_BY_ID: {
			const { ids, response } = payload;
			const fetchingPayload = ids?.reduce((payload, id) => {
				return {
					...payload,
					[id]: false,
				};
			}, {});
			const { pois } = response;
			const dataPayload = ids?.reduce((payload, id) => {
				return {
					...payload,
					[id]: pois.find(({ id: poiId }) => id === poiId),
				};
			}, {});

			return {
				...state,
				byId: {
					...state.byId,
					...dataPayload,
				},
				isFetching: {
					...state.isFetching,
					byId: {
						...state.isFetching.byId,
						...fetchingPayload,
					},
				},
			};
		}

		case POIActions.RECEIVE_POI_INFO_BY_TGID: {
			const { tgid, response } = payload;
			const poiObj: Record<number, TPoi> = {};
			response.pois?.forEach(poiItem => (poiObj[poiItem.id] = poiItem));
			return {
				...state,
				byId: {
					...state.byId,
					...poiObj,
				},
				byTgId: {
					...state.byTgId,
					[tgid]: Object.keys(poiObj).map(key => Number(key)),
				},
				isFetching: {
					...state.isFetching,
					byTgId: {
						...state.isFetching.byTgId,
						[tgid]: false,
					},
				},
			};
		}

		case POIActions.RECEIVE_POI_INFO_BY_COLLECTION_ID: {
			const { collectionId, response } = payload;
			const poiObj: Record<number, TPoi> = {};
			response.pois?.forEach(poiItem => (poiObj[poiItem.id] = poiItem));
			return {
				...state,
				byId: {
					...state.byId,
					...poiObj,
				},
				byCollectionId: {
					...state.byCollectionId,
					[collectionId]: Object.keys(poiObj).map(key => Number(key)),
				},
				isFetching: {
					...state.isFetching,
					byCollectionId: {
						...state.isFetching.byCollectionId,
						[collectionId]: false,
					},
				},
			};
		}

		default:
			return state;
	}
};
