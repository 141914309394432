import * as React from 'react';

export const CrossIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' {...props}>
			<path d='M28.94 31.8L.6 60.1c-.77.8-.77 2.08 0 2.86.4.4.92.6 1.44.6.5 0 1.03-.2 1.42-.6L32 34.42l28.54 28.54c.4.4.9.6 1.43.6.5 0 1.03-.2 1.42-.6.78-.78.78-2.06 0-2.85L35.05 31.8 63.4 3.43c.8-.8.8-2.06 0-2.85-.78-.8-2.05-.8-2.84 0L32 29.14 3.44.6C2.64-.2 1.38-.2.6.6c-.8.78-.8 2.05 0 2.84L28.93 31.8z' />
		</svg>
	);
};

export const CalendarIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 16 16' fill='none' aria-hidden='true' {...props}>
			<path
				d='M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z'
				stroke='#23a1b2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.6667 1.33337V4.00004'
				stroke='#23a1b2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.33334 1.33337V4.00004'
				stroke='#23a1b2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2 6.66675H14'
				stroke='#23a1b2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const TimeIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' {...props}>
			<path
				fillRule='nonzero'
				d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm-1.063 1.875l-.437 8.188.094.874 6.156 5.438.969-1-5.094-5.406-.438-8.094h-1.25z'
			/>
		</svg>
	);
};

export const HourGlassSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox='0 0 10 11' {...props} fill={props?.fill || 'none'}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.12602 1C1.04375 1.31962 1 1.6547 1 2C1 4.20914 2.79086 6 5 6C7.20914 6 9 4.20914 9 2C9 1.6547 8.95625 1.31962 8.87398 1H7.82929C7.93985 1.31278 8 1.64936 8 2C8 3.65685 6.65685 5 5 5C3.34315 5 2 3.65685 2 2C2 1.64936 2.06015 1.31278 2.17071 1H1.12602Z'
			fill={props?.fill || '#444444'}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.87398 10C8.95625 9.68038 9 9.3453 9 9C9 6.79086 7.20914 5 5 5C2.79086 5 1 6.79086 1 9C1 9.3453 1.04375 9.68038 1.12602 10L2.17071 10C2.06015 9.68722 2 9.35064 2 9C2 7.34315 3.34315 6 5 6C6.65685 6 8 7.34315 8 9C8 9.35064 7.93985 9.68722 7.82929 10L8.87398 10Z'
			fill={props?.fill || '#444444'}
		/>
		<line y1='10.5' x2='10' y2='10.5' stroke={props?.fill || '#444444'} />
		<line y1='0.5' x2='10' y2='0.5' stroke={props?.fill || '#444444'} />
	</svg>
);

// @ts-expect-error TS(7031): Binding element 'disabled' implicitly has an 'any'... Remove this comment to see the full error message
export const PlusIcon = ({ disabled }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={disabled ? 'disabled-icon' : ''}
	>
		<circle
			cx='16'
			cy='16'
			r='16'
			fill={disabled ? '#F8F8F8' : '#F3E9FF'}
		/>
		<line
			x1='16.5'
			y1='23'
			x2='16.5'
			y2='8'
			stroke={disabled ? '#BDBDBD' : '#8000ff'}
		/>
		<line
			x1='24'
			y1='15.5'
			x2='9'
			y2='15.5'
			stroke={disabled ? '#BDBDBD' : '#8000ff'}
		/>
	</svg>
);

// @ts-expect-error TS(7031): Binding element 'disabled' implicitly has an 'any'... Remove this comment to see the full error message
export const MinusIcon = ({ disabled }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={disabled ? 'disabled-icon' : ''}
	>
		<circle
			cx='16'
			cy='16'
			r='16'
			fill={disabled ? '#F8F8F8' : '#F3E9FF'}
		/>
		<line
			x1='24'
			y1='15.5'
			x2='9'
			y2='15.5'
			stroke={disabled ? '#BDBDBD' : '#8000ff'}
		/>
	</svg>
);

export const Undo = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M5.99984 6.66797L2.6665 10.0013L5.99984 13.3346'
			stroke='white'
			strokeWidth='1.2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M13.3332 2.66797V7.33464C13.3332 8.04188 13.0522 8.72016 12.5521 9.22025C12.052 9.72035 11.3737 10.0013 10.6665 10.0013H2.6665'
			stroke='white'
			strokeWidth='1.2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const TrashCircle = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<rect width='20' height='20' rx='10' fill='#F0F0F0' />
		<path
			d='M5.5 7H6.5H14.5'
			stroke='#666666'
			strokeWidth='0.85'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M13.5 7V14C13.5 14.2652 13.3946 14.5196 13.2071 14.7071C13.0196 14.8946 12.7652 15 12.5 15H7.5C7.23478 15 6.98043 14.8946 6.79289 14.7071C6.60536 14.5196 6.5 14.2652 6.5 14V7M8 7V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H11C11.2652 5 11.5196 5.10536 11.7071 5.29289C11.8946 5.48043 12 5.73478 12 6V7'
			stroke='#666666'
			strokeWidth='0.85'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M9 9.5V12.5'
			stroke='#666666'
			strokeWidth='0.85'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11 9.5V12.5'
			stroke='#666666'
			strokeWidth='0.85'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const SeatmapExpand = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='13'
		height='12'
		viewBox='0 0 13 12'
		fill='none'
	>
		<path
			d='M2.15971 9.88931L11.3755 2.30225'
			stroke='#888888'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.55863 2.0508L11.3755 2.30257L11.1205 5.08336'
			stroke='#888888'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M4.97684 10.1416L2.15993 9.88982L2.41497 7.10902'
			stroke='#888888'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
