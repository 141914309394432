var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3454ad907ea09de7034b256deff6cc1679e649f6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init as initSentry } from '@sentry/nextjs';

initSentry({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN_CLIENT,
	environment: process.env.NEXT_PUBLIC_NODE_ENV,
	tracesSampler: () => 0,
	debug: false,
	autoSessionTracking: false,
	ignoreUrls: [/https?:\/\/(www\.)?(localhost|next-deimos)\.com/],
});
