import dayjs from 'Utils/dayjsUtil';
import { sanitiseURLProtocol } from 'Utils/imageUtils';
import { hashCode } from 'Utils/integerUtils';
import {
	getCityDisplayName,
	getURLSlugForEntity as getCanonicalURLfromProductUtils,
} from 'Utils/productUtils';

import type { TReview } from 'ReduxTypes/review';

/** ORIGINAL AUTHOR: Debanwita Mahato
 *
 * Returns reviews with image urls by hashing the reviewer name
 * if hash is even, selects one of the default DPs
 * else selects one of the random DPs
 */
export const getRandomImage = (nonCustomerName: string): string => {
	const userNameHash = hashCode(nonCustomerName);
	const totalAvatarVariants = 24;
	const avatarIndex = userNameHash % totalAvatarVariants;
	const isEvenNameLength = nonCustomerName.length % 2 === 0;
	const finalAvatarIndex =
		isEvenNameLength || avatarIndex === 0 ? avatarIndex + 1 : avatarIndex;

	return `https://cdn-imgix.headout.com/reviews/avatars/Avatar_${finalAvatarIndex}.svg`;
};

export const getReviewsWithImageUrls = (
	reviews: TReview[],
): (TReview & { reviewerImageUrl: string })[] | null => {
	if (!reviews) return null;

	return reviews.map(review => ({
		...review,
		reviewerImageUrl:
			review.reviewerImgUrl ??
			getRandomImage(review.nonCustomerName || ''),
	}));
};

export const getReviewsCount = (reviews: any) => {
	if (!reviews) return 0;

	return reviews.filter((review: any) => !!review?.content.length).length;
};

export const getCanonicalURL = ({ product, paramLang }: any) => {
	if (!product) return null;
	return getCanonicalURLfromProductUtils({ product, paramLang });
};

export const getSiteMapMetaData = (product: any, reviews: any) => {
	const { name, imageUploads, reviewsDetails } = product;
	const { averageRating, ratingsCount } = reviewsDetails;
	const imageUrl = imageUploads.length
		? sanitiseURLProtocol(
				imageUploads?.[0]?.url,
				'auto=compress&w=768&h=480&fit=min',
		  )
		: '';

	return reviews.map((review: any) => ({
		'@context': 'https://schema.org/',
		'@type': 'Review',
		itemReviewed: {
			'@type': 'Product',
			image: imageUrl,
			name,
			aggregateRating: {
				'@type': 'AggregateRating',
				ratingValue: averageRating,
				reviewCount: ratingsCount,
			},
		},
		reviewRating: {
			'@type': 'Rating',
			ratingValue: review?.rating,
		},
		name: review?.title,
		author: {
			'@type': 'Person',
			name: review?.nonCustomerName,
		},
		datePublished: dayjs
			.unix(review?.reviewTime / 1000)
			.format('YYYY-MM-DD'),
		reviewBody: review?.content,
		publisher: {
			'@type': 'Organization',
			name: review?.source,
		},
	}));
};

export const getReviewsPageTitle = (product: any) => {
	if (!product) return null;
	return `Reviews - ${product?.name}, ${getCityDisplayName(
		product,
	)} - Headout`;
};

export const getReviewsMetaDescription = (product: any) => {
	if (!product) return null;
	return `Find out what genuine customers are saying about booking ${product?.name} from Headout. Real reviews from real people to get insights before booking online.`;
};

export const getReviewsFromStore = (
	store: any,
	tourGroupId: any,
	filterType: string = 'TOP',
) => {
	return store?.byTgIdAndFilter?.[
		evaluateReviewStoreKey(tourGroupId, filterType)
	];
};

export const evaluateReviewStoreKey = (tourGroupId: any, filterType: any) => {
	return `${tourGroupId}-${filterType}`;
};
