import { setTag as setSentryTag } from '@sentry/nextjs';
import Cookies from 'js-cookie';

import { trackEvent } from 'Utils/analytics';
import { isServer } from 'Utils/envUtils';
import { error } from 'Utils/logUtils';
import UniqueId from 'Utils/uniqueId';
import { getQueryObject } from 'Utils/urlUtils';

import { COOKIE, HSID_DEFAULT_VALUE } from 'Constants/constants';
import { EXPERIMENTS, VARIANTS } from 'Constants/experiments';

const tagExperimentForSentry = (experiment: any, variant: string) => {
	const [experimentTreatment, experimentControl] = experiment.bucketWeight;
	const isFullyResolvedBucket =
		(experimentTreatment === 100 && experimentControl === 0) ||
		(experimentTreatment === 0 && experimentControl === 100);

	// To establish an experiment that is resolved by a server function, we should track it using a server prop and subsequently tag it on the client side
	if (
		!isServer() &&
		typeof window !== 'undefined' &&
		!isFullyResolvedBucket
	) {
		setSentryTag(experiment.experimentName, variant);
	}
};

export const resolveBucket = (experiment: any, cookies: any) => {
	const sandboxId = cookies?.[COOKIE.SANDBOX_ID];
	if (!sandboxId) {
		error('null sandbox id', cookies, experiment.experimentName);
	}
	const uniqueId = sandboxId ? new UniqueId(sandboxId) : null;
	return experiment.getBucket(uniqueId);
};

export const getABTestingVariant = (
	EXPERIMENT_TYPE: any,
	cookies: any,
	noTrack = false,
) => {
	const experiment = (EXPERIMENTS as any)[EXPERIMENT_TYPE];
	const variant = resolveBucket(experiment, cookies);
	if (!noTrack)
		trackEvent({
			eventName: 'Experiment Viewed',
			'Experiment Name': (experiment as any).experimentName,
			'Experiment Variant': variant,
		});

	tagExperimentForSentry(experiment, variant);

	return variant;
};

export const getABTestingVariantBySandboxId = (
	EXPERIMENT_NAME: string,
	sandboxId: any,
	noTrack = false,
	additionalEventProps: any = {},
) => {
	const experiment = EXPERIMENTS[EXPERIMENT_NAME as keyof typeof EXPERIMENTS];
	const experimentOverride =
		typeof window !== 'undefined'
			? getQueryObject(window?.location)?.[COOKIE.EXPERIMENT_OVERRIDE] ??
			  Cookies.get('experimentOverride')
			: null;
	let experimentOverrideVariant = null;
	if (
		experimentOverride === VARIANTS.CONTROL ||
		experimentOverride === VARIANTS.TREATMENT
	)
		experimentOverrideVariant = experimentOverride;
	else if (
		typeof experimentOverride === 'string' &&
		experimentOverride.substring(0, 3).toLowerCase() ===
			EXPERIMENT_NAME.substring(0, 3).toLowerCase()
	)
		experimentOverrideVariant = experimentOverride.substring(4);

	const fullRolloutIndex = experiment?.bucketWeight.indexOf(100);
	const fullRolloutVariant = experiment?.bucketName[fullRolloutIndex] || '';

	const variant: string =
		experimentOverrideVariant ||
		fullRolloutVariant ||
		experiment.getBucket(new UniqueId(sandboxId));
	if (!noTrack && sandboxId !== HSID_DEFAULT_VALUE)
		trackEvent({
			eventName: 'Experiment Viewed',
			'Experiment Name': experiment.experimentName,
			'Experiment Variant': variant,
			...additionalEventProps,
		});

	tagExperimentForSentry(experiment, variant);

	return variant;
};

export const getExperimentVariables = (experimentName: any) =>
	(window &&
		(window as any).experiments &&
		(window as any).experiments[experimentName]) ||
	{};
