// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import cloneDeep from 'lodash.clonedeep';
import type { Booking } from 'src/types/booking';
import type { CartItem } from 'src/types/cart';

import {
	getBookingId,
	getTourSelectionState,
	getUserFieldIndex,
	getUserFieldsArray,
	initialBookingState,
	isBroadwayShow,
} from 'Utils/bookingUtils';
import type { ICartSelectionItemDetails } from 'Utils/crossSell/types';
import { customMergeDeep } from 'Utils/gen';
import { error } from 'Utils/logUtils';
import {
	getInventoriesForTuple,
	getPeopleSelectionsFromProduct,
} from 'Utils/pricingUtils';

import { ActionTypes } from 'Actions/actions';

import {
	BOOKING_STATUS,
	DATA_SOURCE_TYPE,
	FIELD_NAME,
	PROFILE_TYPE,
	THUNK_ERROR_CODES,
} from 'Constants/constants';

export const bookings = (state: any = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_BOOKING_SLOT: {
			const {
				id,
				pricing,
				people,
				retainSelectionMap,
				fallbackToBookingTime = true,
			} = action;
			let { time, tourId } = action;
			let booking = state?.[id];
			if (booking) {
				const {
					selectedDate,
					selectedTime: bookingTime,
					selectedTourId: bookingTourId,
					selectionMap: currentSelectionMap,
					groupSize: currentGroupSize,
					userFields: oldUserFields,
				} = booking;
				tourId = tourId || bookingTourId;
				if (fallbackToBookingTime) {
					time = time || bookingTime;
				}
				const inventories = getInventoriesForTuple(
					{ date: selectedDate, time, tourId },
					pricing,
				);

				if (inventories) {
					let { selectionMap, groupSize } =
						getPeopleSelectionsFromProduct(
							inventories?.[0],
							pricing,
						);
					if (people?.selectionMap) {
						selectionMap = people?.selectionMap;
						groupSize = people?.groupSize;
					}

					// retain pax selections on slot change in broadway flow
					if (isBroadwayShow(id) || retainSelectionMap) {
						selectionMap = currentSelectionMap;
						groupSize = currentGroupSize;
					}
					const userFields = getUserFieldsArray({
						pricing,
						booking,
						tourId,
						oldUserFields,
						selectionMap,
						groupSize,
					});

					booking = {
						...booking,
						selectedTime: time,
						selectedTourId: tourId,
						selectionMap,
						groupSize,
						promoObject: {},
						userFields,
					};
				} else {
					error(THUNK_ERROR_CODES.INVENTORY_EXPIRED);
				}
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_USER_FIELDS: {
			const { id, pricing } = action;
			let { time, tourId } = action;
			let booking = state?.[id];
			if (booking) {
				const {
					selectedDate,
					selectedTime: bookingTime,
					selectedTourId: bookingTourId,
					userFields: oldUserFields,
				} = booking;
				tourId = tourId || bookingTourId;
				time = time || bookingTime;
				const inventories = getInventoriesForTuple(
					{ date: selectedDate, time, tourId },
					pricing,
				);
				if (inventories) {
					const { selectionMap, groupSize } = booking;
					const userFields = getUserFieldsArray({
						pricing,
						booking,
						tourId,
						oldUserFields,
						selectionMap,
						groupSize,
					});
					booking = {
						...booking,
						selectedTime: time,
						selectedTourId: tourId,
						selectionMap,
						groupSize,
						promoObject: {},
						userFields,
					};
				} else {
					error(THUNK_ERROR_CODES.INVENTORY_EXPIRED);
				}
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_BULK_BOOKING_USER_FIELDS: {
			const { productIds, pricingStore } = action;
			const newState = productIds.reduce(
				(accState: Record<string, Booking>, id: string) => {
					const booking = accState[id];

					if (!booking) {
						error('Booking object not set', id);
						return accState;
					}

					const pricing = pricingStore[id];
					const {
						selectedDate,
						selectedTime: bookingTime,
						selectedTourId: bookingTourId,
						userFields: oldUserFields,
					} = booking;

					const inventories = getInventoriesForTuple(
						{
							date: selectedDate,
							time: bookingTime,
							tourId: bookingTourId,
						},
						pricing,
					);

					if (!inventories) {
						error(THUNK_ERROR_CODES.INVENTORY_EXPIRED, id);
						return accState;
					}

					const { selectionMap, groupSize } = booking;

					const userFields = getUserFieldsArray({
						pricing,
						booking,
						tourId: bookingTourId,
						oldUserFields,
						selectionMap,
						groupSize,
					});

					const updatedBooking = { ...booking, userFields };

					return { ...accState, [id]: updatedBooking };
				},
				{ ...state },
			);

			return newState;
		}

		case ActionTypes.SET_BOOKING_SEATMAP: {
			const { id, seatMapInfo } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					seatMapInfo: seatMapInfo,
				};
			} else {
				throw new Error('Booking object not set-');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_DATE: {
			const { id, date, defaultSelection } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					selectedDate: date,
					isDefaultDate: defaultSelection,
				};
				if (!date) {
					booking = {
						...booking,
						selectedTime: null,
						selectedTourId: null,
					};
				}
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_TIME: {
			const { time, defaultSelection, id } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					selectedTime: time,
					isDefaultTime: defaultSelection,
				};
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_TOUR_ID: {
			const { id, tourId, defaultSelection } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					selectedTourId: tourId,
					isDefaultTour: defaultSelection,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_FILTER_VALUE: {
			const { id, filterValue, defaultSelection } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					filterValue,
					isDefaultfilterValue: defaultSelection,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_VARIANT_ID: {
			const { id, variantId, defaultSelection } = action.payload;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					selectedVariantId: variantId,
					isDefaultVariant: defaultSelection,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_TOUR_DATE: {
			const { id, date, tourId } = action.payload;
			let booking = state?.[id];
			if (booking) {
				if (!booking?.comboSelections) {
					booking = {
						...booking,
						promoObject: {},
						comboSelections: {},
					};
				}
				const comboSelection = {
					[tourId]: {
						selectedTourDate: date,
					},
				};
				booking = customMergeDeep(booking, {
					comboSelections: comboSelection,
				});
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_TOUR_TIME: {
			const { id, time, tourId, defaultSelection } = action.payload;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					promoObject: {},
					comboSelections: {
						...booking['comboSelections'],
						[tourId]: {
							...booking['comboSelections'][tourId],
							selectedTourTime: time,
							isDefaultTime: defaultSelection,
						},
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_TOUR_SLOT: {
			const { id, tourId, pricing } = action;
			let booking = state?.[id];
			if (booking) {
				const tourSelections = getTourSelectionState(booking, tourId);
				if (tourSelections) {
					const {
						selectedTourDate: date,
						selectedTourTime: time,
						tourUserFields: oldUserFields,
					} = tourSelections;
					const inventories = getInventoriesForTuple(
						{ date, time, tourId },
						pricing,
					);
					if (inventories) {
						const { selectionMap, groupSize } =
							getPeopleSelectionsFromProduct(
								inventories?.[0],
								pricing,
							);
						const userFields = getUserFieldsArray({
							pricing,
							booking,
							tourId,
							oldUserFields,
							selectionMap,
							groupSize,
						});
						const comboSelection = {
							[tourId]: {
								tourUserFields: userFields,
							},
						};
						booking = customMergeDeep(booking, {
							comboSelections: comboSelection,
						});
						booking = {
							...booking,
							promoObject: {},
						};
					} else {
						error(THUNK_ERROR_CODES.INVENTORY_EXPIRED);
					}
				}
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_TOUR_PEOPLE: {
			const { id, tourId, pricing } = action.payload;
			let { selectionMap, groupSize } = action.payload;
			let booking = state?.[id];
			if (booking) {
				const tourSelections = getTourSelectionState(booking, tourId);
				if (tourSelections) {
					const { tourUserFields: oldUserFields } = tourSelections;
					selectionMap = selectionMap || {};
					groupSize = groupSize || 0;
					const userFields = getUserFieldsArray({
						pricing,
						booking,
						tourId,
						oldUserFields,
						selectionMap,
						groupSize,
					});
					if (!booking?.comboSelections) {
						booking = {
							...booking,
							comboSelections: {},
						};
					}
					const comboSelection = {
						[tourId]: {
							tourSelectionMap: selectionMap,
							tourGroupSize: groupSize,
						},
					};
					booking = customMergeDeep(booking, {
						comboSelections: comboSelection,
					});
					booking = {
						...booking,

						comboSelections: {
							...booking['comboSelections'],

							[tourId]: {
								...booking['comboSelections'][tourId],
								tourUserFields: userFields,
							},
						},
					};
				}
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_AUTO_APPLIED_COUPON: {
			const { id } = action;
			const { couponCode } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					autoAppliedCoupon: couponCode,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_LANG: {
			const { id, lang = 'en' } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					language: lang,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_ITINERARY_COMMENT: {
			const { id, comment } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					itineraryComment: comment,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.MAKING_BOOKING: {
			const { id } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,

					bookingResponse: {
						...booking['bookingResponse'],
						status: BOOKING_STATUS.PENDING,
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.REQUEST_USER_ACTION: {
			const { id } = action;
			let booking = (state as any)?.[id];
			if (booking) {
				booking = {
					...booking,
					bookingResponse: {
						...booking['bookingResponse'],
						status: '',
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.MADE_BOOKING: {
			const { id, status } = action; // const {id, json, status} = action;
			// TODO: parse json and update
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,

					bookingResponse: {
						...booking['bookingResponse'],
						status: status,
					},
				}; // Do failed based on parsing
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_USER_DETAILS: {
			const { id, userDetails } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					userFields: userDetails,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_TOUR_USER_DETAILS: {
			const { id, tourId, userDetails } = action;
			let booking = state?.[id];
			if (booking) {
				const comboSelections = {
					[tourId]: {
						tourUserFields: userDetails,
					},
				};
				booking = customMergeDeep(booking, { comboSelections });
				booking = {
					...booking,
					userFields: userDetails,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_COUNTRY_DIAL_CODE: {
			const { id, code } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					countryDialCode: code,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_USER_FIELD_VALUES: {
			const { id, userFieldValues } = action;
			const { name, email, phone } = userFieldValues;
			let booking = state?.[id];
			if (booking) {
				const { userFields } = booking;

				const nameIndex = getUserFieldIndex(
					userFields,
					FIELD_NAME.NAME,
				);
				const emailIndex = getUserFieldIndex(
					userFields,
					FIELD_NAME.EMAIL,
				);
				const phoneIndex = getUserFieldIndex(
					userFields,
					FIELD_NAME.PHONE,
				);
				let updatedUserFields = cloneDeep(userFields);
				updatedUserFields[0][nameIndex].value = name;
				updatedUserFields[0][emailIndex].value = email;
				updatedUserFields[0][phoneIndex].value = phone;
				booking = {
					...booking,
					userFields: updatedUserFields,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_TOUR_USER_FIELD_VALUES: {
			const { id, tourId, userFieldValues } = action;
			const { name, email, phone } = userFieldValues;
			let booking = state?.[id];
			if (booking) {
				const { tourUserFields: userFields } = getTourSelectionState(
					booking,
					tourId,
				);
				const nameIndex = getUserFieldIndex(
					userFields,
					FIELD_NAME.NAME,
				);
				const emailIndex = getUserFieldIndex(
					userFields,
					FIELD_NAME.EMAIL,
				);
				const phoneIndex = getUserFieldIndex(
					userFields,
					FIELD_NAME.PHONE,
				);
				let updatedUserFields = cloneDeep(userFields);
				updatedUserFields[0][nameIndex].value = name;
				updatedUserFields[0][emailIndex].value = email;
				updatedUserFields[0][phoneIndex].value = phone;
				const comboSelections = {
					[tourId]: {
						tourUserFields: updatedUserFields,
					},
				};
				booking = customMergeDeep(booking, { comboSelections });
				booking = {
					...booking,
					userFields: updatedUserFields,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.FETCHING_BREAKUP: {
			const { id, promoCode } = action;
			let booking = state?.[id];
			if (booking) {
				const promoObject = {
					isApplied: false,
					promoCode,
					appliedPromoCode: promoCode,
					couponType: booking.promoObject?.couponType,
					hasShownPromoSuccessModal:
						booking?.promoObject?.hasShownPromoSuccessModal,
				};
				booking = {
					...booking,
					promoObject: promoObject,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.FETCHED_BREAKUP: {
			const { id, couponCode, breakup } = action;
			const { usingCoupon, appliedCoupon } = breakup;
			let booking = state?.[id];
			if (booking) {
				const promoObject = {
					promoCode: couponCode || appliedCoupon?.code,
					appliedPromoCode: couponCode || appliedCoupon?.code,
					isApplied: usingCoupon,
					shallApplyPromoCode: false,
					couponType: appliedCoupon?.discountType,
					hasShownPromoSuccessModal:
						booking?.promoObject?.hasShownPromoSuccessModal,
				};

				booking = {
					...booking,
					promoObject: promoObject,
				};
			} else {
				throw new Error(`Booking object not set`);
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_PROMO_CODE: {
			const { id, promoCode } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,

					promoObject: {
						...booking['promoObject'],
						promoCode: promoCode,
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.APPLY_PROMO_CODE: {
			const { id } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,

					promoObject: {
						...booking['promoObject'],
						shallApplyPromoCode: true,
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_HAS_SHOWN_PROMO_SUCCESS_MODAL: {
			const { id, hasShown } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,

					promoObject: {
						...booking['promoObject'],
						hasShownPromoSuccessModal: hasShown,
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_BOOKING_STAGE: {
			const { id, stage } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					stage: stage,
				};
			} else {
				throw new Error('Booking object not set');
			}

			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.RESET_BOOKING: {
			const { id } = action;
			const booking = {
				...initialBookingState,
				id: Number(id),
			};
			return {
				...state,
				[String(id)]: booking,
			};
		}
		case ActionTypes.REMOVE_BOOKING: {
			const { id } = action;
			delete state[String(id)];
			return state;
		}
		case ActionTypes.REMOVE_ALL_RECOMMENDATIONS_BOOKINGS: {
			const { id } = action;
			return { [id]: state[id] };
		}
		case ActionTypes.RESET_BULK_BOOKING: {
			const { ids } = action;
			const bookingObject: Record<string, any> = {};
			ids?.forEach((id: string) => {
				if (state[id]) return;
				bookingObject[id] = { ...initialBookingState, id: Number(id) };
			});
			return {
				...bookingObject,
				...state,
			};
		}
		case ActionTypes.SET_BOOKING_PEOPLE: {
			const { id, pricing } = action;
			let { selectionMap, groupSize } = action;
			let booking = state?.[id];
			if (booking) {
				const { selectedTourId: tourId, userFields: oldUserFields } =
					booking;
				selectionMap = selectionMap || {};
				groupSize = groupSize || 0;
				const userFields = getUserFieldsArray({
					pricing,
					booking,
					tourId,
					oldUserFields,
					selectionMap,
					groupSize,
				});
				booking = {
					...booking,
					selectionMap,
					groupSize,
					userFields,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_RESPONSE_DATA: {
			const {
				id,
				bookingResponse,
				resetBookingResponse = false,
			} = action;

			if (!resetBookingResponse) {
				bookingResponse.duration = bookingResponse.bookings[0].duration;
				bookingResponse.inventoryDiscount =
					bookingResponse.bookings[0].inventoryDiscount;
				bookingResponse.inventoryLocalDate =
					bookingResponse.bookings[0].inventoryLocalDate;
				bookingResponse.inventoryLocalTime =
					bookingResponse.bookings[0].inventoryLocalTime;
				bookingResponse.freeTourInfo =
					bookingResponse.bookings[0].freeTourInfo;
			}
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					bookingResponse: resetBookingResponse
						? initialBookingState.bookingResponse
						: bookingResponse,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOKING_ID: {
			const { id, bookingResponse, resetBookingId = false } = action;
			let booking = state?.[id];
			if (booking) {
				const isCombo = bookingResponse?.bookings?.length > 1;
				if (isCombo) {
					bookingResponse.bookings.forEach((bookingInfo: any) => {
						const { tourId } = bookingInfo;
						const bookingId = resetBookingId
							? null
							: getBookingId(bookingResponse, tourId);
						const comboSelections = {
							[tourId]: {
								bookingId,
							},
						};
						booking = customMergeDeep(booking, { comboSelections });
					});
				} else {
					const bookingId = resetBookingId
						? null
						: getBookingId(bookingResponse);
					booking = {
						...booking,
						bookingId: bookingId,
					};
				}
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_BOOK_BUTTON_LOCK: {
			const { id, status } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					isBookButtonLocked: status,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_PAYMENT_ERROR: {
			const { id, error } = action.payload;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					paymentError: error,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_FINGERPRINT_DETAILS: {
			const { id, details } = action.payload;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					fingerprintVisitorDetails: details,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_ITINERARY_DETAILS: {
			const { id, itineraryDetails } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					itineraryDetails: itineraryDetails,
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.SET_INSTANT_CHECKOUT_TYPE: {
			const { id } = action;
			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					instantCheckout: true,
				};
			} else {
				throw new Error('Booking object not set-');
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.REQUEST_RESERVE_SESSION_KEY:
			const { id, selectedVariantId } = action;
			let booking = state?.[id];
			if (booking) {
				const fetchingStateForId =
					booking.reserveSessions?.isFetching?.[String(id)];
				booking = {
					...booking,
					reserveSessions: {
						isFetching: {
							...booking.reserveSessions?.isFetching,
							[String(id)]: selectedVariantId
								? {
										...fetchingStateForId,
										[String(selectedVariantId)]: true,
								  }
								: true,
						},
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};

		case ActionTypes.CANCEL_RESERVE_SESSION_KEY: {
			const { id, selectedVariantId } = action;
			let booking = state?.[id];
			if (booking) {
				const fetchingStateForId =
					booking.reserveSessions?.isFetching?.[String(id)];
				booking = {
					...booking,
					reserveSessions: {
						isFetching: {
							...booking.reserveSessions?.isFetching,
							[String(id)]: selectedVariantId
								? {
										...fetchingStateForId,
										[String(selectedVariantId)]: false,
								  }
								: false,
						},
					},
				};
			}

			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.RECEIVE_RESERVE_SESSION_KEY: {
			const { id, selectedVariantId, payload } = action;
			const { sessionId, sessionExpiry, seats, reserveSessionId } =
				payload;
			let booking = state?.[id];
			if (booking) {
				const fetchingStateForId =
					booking.reserveSessions?.isFetching?.[String(id)];
				booking = {
					...booking,
					reserveSessions: {
						isFetching: {
							...booking.reserveSessions?.isFetching,
							[String(id)]: selectedVariantId
								? {
										...fetchingStateForId,
										[String(selectedVariantId)]: false,
								  }
								: false,
						},
						sessions: {
							[String(id)]: {
								[String(selectedVariantId)]: {
									sessionId,
									sessionExpiry,
									seats,
									reserveSessionId,
								},
							},
						},
					},
				};
			} else {
				throw new Error('Booking object not set');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.REQUEST_COMBO_UPSELL_DATA: {
			const {
				payload: { id, tourId },
			} = action;

			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					comboUpsell: {
						status: {
							isFetching: {
								[tourId]: true,
							},
						},
					},
				};
			} else {
				throw new Error('Booking object not set-');
			}
			return {
				...state,
				[id]: booking,
			};
		}
		case ActionTypes.RECEIVE_COMBO_UPSELL_DATA: {
			const {
				payload: { id, tourId, comboUpsellData },
			} = action;

			let booking = state?.[id];
			if (booking) {
				booking = {
					...booking,
					comboUpsell: {
						//TGID is handled at the top - effectively a combination of TGID + TID
						data: {
							[tourId]: comboUpsellData,
						},
						status: {
							isFetching: {
								[tourId]: false,
							},
						},
					},
				};
			} else {
				throw new Error('Booking object not set-');
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_AIRPORT_TRANSFERS_BOOKING_QUOTE_ID: {
			const { id, quoteId } = action;
			let booking = state?.[id];

			if (booking) {
				booking = {
					...booking,
					airportTransfers: {
						...booking.airportTransfers,
						quoteId,
					},
				};
			} else {
				throw new Error('Booking object not set-');
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_VARIANT_FLOW: {
			const { id, isExperimentalFlow } = action;
			let booking = state?.[id];

			if (booking) {
				booking = {
					...booking,
					isExperimentalFlow: isExperimentalFlow,
				};
			} else {
				throw new Error('Booking object not set-');
			}
			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.ADD_CART_SELECTIONS: {
			const {
				bookingObjects,
				lang,
			}: { bookingObjects: Record<number, CartItem>; lang: string } =
				action;
			const booking = state;
			Object.entries(bookingObjects ?? {}).forEach(([tgid, cartObj]) => {
				if (!booking[tgid] || cartObj.tourIds.length > 1) return;
				const {
					inventoryDate: cartDate,
					inventoryTime: cartTime,
					paxInformation,
					priceProfileType,
				} = cartObj.tourInformation[cartObj.tourIds[0]];
				const cartSelectionMap =
					priceProfileType === PROFILE_TYPE.PER_PERSON
						? paxInformation
						: null;
				const cartGroupSize =
					priceProfileType === PROFILE_TYPE.PER_GROUP
						? paxInformation.GROUP
						: 0;
				const {
					selectedDate: bookingDate,
					selectedTime: bookingTime,
					selectedTourId: bookingTourId,
					selectedVariantId: bookingVariantId,
					selectionMap: bookingSelectionMap,
					groupSize: bookingGroupSize,
				} = booking[tgid];
				booking[tgid] = {
					...booking[tgid],
					selectedDate: bookingDate || cartDate,
					selectedTime: bookingTime || cartTime,
					selectedTourId: bookingTourId || cartObj.tourIds[0],
					selectedVariantId: bookingVariantId || cartObj.variantId,
					selectionMap: bookingSelectionMap || cartSelectionMap,
					groupSize: bookingGroupSize || cartGroupSize,
					language: lang,
				};
			});
			return booking;
		}

		case ActionTypes.TRANSFER_CART_SELECTION_TO_BOOKINGS: {
			const {
				id,
				selectedDate,
				selectedTime,
				selectedTourId,
				selectedVariantId,
				selectionMap,
				groupSize,
				isDefaultDate,
				isDefaultTime,
				isDefaultVariant,
				language,
			} = action as ICartSelectionItemDetails & {
				type: typeof ActionTypes.TRANSFER_CART_SELECTION_TO_BOOKINGS;
			};
			let booking = state?.[String(id)];

			if (booking) {
				booking = {
					...booking,
					selectedDate,
					selectedTime,
					selectedTourId,
					selectedVariantId,
					selectionMap,
					groupSize,
					language,
					id: String(id),
					isDefaultDate,
					isDefaultTime,
					isDefaultVariant,
				};
			}

			return {
				...state,
				[String(id)]: booking,
			};
		}

		case ActionTypes.SET_ADYEN_BROWSER_INFO: {
			const { id, browserInfo } = action;
			let booking = state?.[id];

			booking = {
				...booking,
				browserInfo,
			};

			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_BOOKING_FLEXI_CANCELLATION_OPT_IN: {
			const { id, optIn } = action;
			let booking = state?.[id];

			booking = {
				...booking,
				flexiCancellationOptIn: optIn,
			};

			return {
				...state,
				[id]: booking,
			};
		}

		case ActionTypes.SET_BOOKING_BROADWAY_FLOW: {
			const { id, isExperimentalBroadwayFlow } = action;
			let booking = state?.[id];

			booking = {
				...booking,
				isExperimentalBroadwayFlow,
			};

			return {
				...state,
				[id]: booking,
			};
		}

		default:
			return state;
	}
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const checkoutExtraInformation = (state = { flag: false }, action) => {
	switch (action.type) {
		case ActionTypes.SET_EXTRA_INFORMATION_FLAG: {
			const { flag } = action;
			return {
				...state,
				flag,
			};
		}
		default:
			return state;
	}
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const seatSVGs = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_SEATING_SVG: {
			const { id } = action;
			return {
				...state,
				[id]: { isFetching: true },
			};
		}
		case ActionTypes.RECEIVE_SEATING_SVG: {
			const { id, svg, dataSource } = action;
			return {
				...state,
				[id]: {
					svg,
					dataSource,
					isFetching: !(dataSource === DATA_SOURCE_TYPE.API),
				},
			};
		}
		default:
			return state;
	}
};
