/* eslint-disable no-console */
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import SmartLook from 'Components/common/smartLook';

import { getAnalyticsFlowType } from 'Utils/bookingFlowUtils';
import {
	getNameFromUserFields,
	getPhoneNumberFromUserFields,
	getUserEmailFromUserFields,
} from 'Utils/bookingUtils';
import { getDiscount, getPricePayable } from 'Utils/breakupUtils';
import { getCookie } from 'Utils/cookieUtils';
import { countNoOfDaysFromDate } from 'Utils/dateUtils';
import defer from 'Utils/deferUtils';
import { isProductionEnvironment, isServer } from 'Utils/envUtils';
import { jsonEquals } from 'Utils/gen';
import { read, remove, write } from 'Utils/localStorageUtils';
import {
	getPrimaryCollectionId,
	getPrimaryCollectionName,
} from 'Utils/productUtils';
import { getAppliedPromoCode } from 'Utils/promoUtils';
import { getFirstLastName } from 'Utils/stringUtils';

import {
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	TRIGGERED_BY,
} from 'Constants/analytics';
import {
	FINGERPRINT_CONFIG,
	HEADOUT_DOMAIN,
	HSID_COOKIE_NAME,
	SEARCH_ENTITY_TYPE,
} from 'Constants/constants';

import { sendException } from './coralogix/log';

// This function is the single source of truth to send events to DataLayer

export const trackEvent = ({ eventName, ...labelProps }: any) => {
	if (isServer()) return;
	if (typeof window !== 'undefined' && !(window as any).dataLayer) {
		console.group('trackEvent failed!');
		console.log({ eventName, labelProps });
		console.groupEnd();
		return;
	}
	const allProps = {
		event: eventName,
		...labelProps,
	};
	(window as any).dataLayer.push(allProps);
};

export const sendVariableToDataLayer = ({ name, value }: any) => {
	if (isServer()) return;
	if (typeof window !== 'undefined' && !(window as any).dataLayer) {
		console.group('sendVariableToDataLayer failed!');
		console.log({ name, value });
		console.groupEnd();
		return;
	}

	const lastVariableEntry = (window as any).dataLayer[
		(window as any).dataLayer
			.map((prop: {}) => Object.keys(prop)[0])
			.lastIndexOf(name)
	];

	if (lastVariableEntry) {
		const prevValue = Object.values(lastVariableEntry)[0];
		const isEqual =
			typeof prevValue === 'string'
				? prevValue === value
				: jsonEquals(prevValue, value);
		if (isEqual) return;
	}

	(window as any).dataLayer.push({
		[name]: value,
	});
};

// @ts-expect-error TS(7031): Binding element 'pageType' implicitly has an 'any'... Remove this comment to see the full error message
export const trackPageView = ({ pageType, ...otherProps }) => {
	trackEvent({
		eventName: `${pageType} Viewed`,
		...otherProps,
	});
	sendVariableToDataLayer({
		name: 'Page Type',
		value: pageType,
	});
};

// @ts-expect-error TS(7031): Binding element 'pageType' implicitly has an 'any'... Remove this comment to see the full error message
export const trackPageLoaded = ({ pageType, ...otherProps }) => {
	trackEvent({
		eventName: `${pageType} Loaded`,
		...otherProps,
	});
};

export const trackButtonClick = ({ buttonText, intent }: any) => {
	trackEvent({
		eventName: 'ButtonClicked',
		'Button Text': buttonText,
		Intent: intent,
	});
};

export const trackCheckoutFieldAdded = ({
	fieldName,
	fieldValue,
	triggeredBy = TRIGGERED_BY.USER,
}: any) => {
	if (!fieldValue?.trim()?.length) return;
	trackEvent({
		eventName: 'Checkout Customer Field Added',
		[ANALYTICS_PROPERTIES.FIELD_NAME]: fieldName,
		[ANALYTICS_PROPERTIES.FIELD_VALUE]: fieldValue,
		[ANALYTICS_PROPERTIES.TRIGGERED_BY]: triggeredBy,
	});
};

export const trackCheckoutDropdownClicked = ({
	fieldName,
	dropdownType,
	...properties
}: {
	fieldName?: string;
	dropdownType?: string;
	[x: string]: any;
}) => {
	trackEvent({
		eventName: `${dropdownType} Dropdown Clicked`,
		...(fieldName && { [ANALYTICS_PROPERTIES.FIELD_NAME]: fieldName }),
		...properties,
	});
};

export const trackPaymentFieldAdded = ({
	fieldName,
	paymentType,
	paymentGateway,
	paymentGatewayType,
}: any) => {
	trackEvent({
		eventName: 'Payment Field Added',
		[ANALYTICS_PROPERTIES.FIELD_NAME]: fieldName,
		[ANALYTICS_PROPERTIES.PAYMENT_TYPE]: paymentType,
		[ANALYTICS_PROPERTIES.PAYMENT_GATEWAY]: paymentGateway,
		[ANALYTICS_PROPERTIES.PAYMENT_GATEWAY_TYPE]: paymentGatewayType,
	});
};

/**
 *  These two functions send user attributes to ECommerceAnalytics + other sources
 *  WARNING: Do not send sessionId. It is auto managed.
 * */
export const identifyUser = ({
	emailId = '',
	attributes,
}: {
	emailId?: string;
	attributes?: Record<string, any>;
}) => {
	if (isServer()) return;
	// Identify calls should be deferred because they do not rely on context variable (url, path etc.)
	defer(() => {
		let traits = {
			emailId,
			hsid: getCookie(HSID_COOKIE_NAME),
		};
		if (attributes) {
			let walletCurrencyCode = null;
			const {
				email,
				name,
				phoneNumber,
				userId,
				imageUrl,
				walletCurrency,
				walletCredits,
			} = attributes;
			if (walletCurrency) {
				const { code } = walletCurrency;
				walletCurrencyCode = code;
			}
			traits = {
				...traits,
				...(email && { email }),
				...(name && { name }),
				...(phoneNumber && { phoneNumber }),
				...(userId && { userId }),
				...(imageUrl && { imageUrl }),
				...(walletCurrency &&
					walletCurrencyCode && { walletCurrencyCode }),
				...(walletCurrency && { walletCredits }),
			};
		}
		trackEvent({
			eventName: 'User Identified',
			email: (traits as any).email,
			traits,
		});
		(SmartLook as any).identifyUser(traits);
	});
};

export const setUserAttributes = (attributes: any) => {
	const { name, email, phone, phoneCountryName, countryDialCode } =
		attributes;
	const { firstName, lastName } = getFirstLastName(name);
	const userAttributes = {
		...(firstName && { we_first_name: firstName }),
		...(lastName && { we_last_name: lastName }),
		...(email && { we_email: email }),
		...(phone && { we_phone: phone }),
		...(phoneCountryName && { phoneCountryName }),
		...(countryDialCode && { countryDialCode: `+${countryDialCode}` }),
	};
	// trackEvent({
	// 	eventName: 'Set User Attributes',
	// 	'User Attributes': userAttributes,
	// });
};

export const identifyGuestUser = ({ userFields }: any) => {
	if (!userFields) return;
	const basicUserAttributes = {
		name: getNameFromUserFields({ userFields }),
		email: getUserEmailFromUserFields({ userFields }),
		phone: getPhoneNumberFromUserFields({ userFields }),
	};
	// Identify calls should be deferred because they do not rely on context variable (url, path etc.)
	defer(() => {
		trackEvent({
			eventName: 'identifyGuestUser',
			...basicUserAttributes,
		});
		// Setting attributes obtained from user fields
		setUserAttributes(basicUserAttributes);
	});
};

export const trackUserLogout = () => {
	trackEvent({
		eventName: 'User Logout',
	});
};

export const sendUserVariablesToDataLayer = ({ name, userId }: any) => {
	sendVariableToDataLayer({
		name: 'Customer Name',
		value: name,
	});
	sendVariableToDataLayer({
		name: 'User Id',
		value: String(userId),
	});
};

export const trackOrderFailure = (
	bookingRequest: any,
	breakup: any,
	error: any,
) => {
	const { booking, product } = bookingRequest;
	const { id } = booking;
	const pricePayable = Number(getPricePayable(breakup));
	const appliedPromoCode = getAppliedPromoCode(booking) || null;
	const discount = getDiscount(breakup);
	// @ts-expect-error TS(2531): Object is possibly 'null'.
	const orderValue = pricePayable + discount;
	const errorCode = error.code;
	const errorMessage = error.message;
	trackEvent({
		eventName: 'Order Failed',
		'Tour Group ID': id,
		'Flow Type': getAnalyticsFlowType(product),
		'Order Value': orderValue,
		'Coupon Code': appliedPromoCode,
		'Error Code': errorCode,
		'Error Reason': errorMessage,
	});
};

export type trackDateSelectedProps = Record<string, any | undefined>;

export const trackDateSelected = ({
	flowType,
	date,
	prevDate,
	tourId,
	tourIds,
	tgid,
	leadingDays,
	isDefaultDate,
	eventName = 'Experience Date Selected',
	hasDiscount = false,
	availableToursCount,
	...otherProps
}: trackDateSelectedProps) => {
	const properties: trackDateSelectedProps = {
		eventName,
		'Experience Date': date,
		Ranking: tourIds ? tourIds?.indexOf(Number(tourId)) + 1 : null,
		'No of Tours': tourIds?.length ?? null,
		'Tour Group ID': Number(tgid) ?? null,
		'Tour ID': tourId ?? null,
		'Flow Type': flowType,
		'Lead Time Days': leadingDays ?? null,
		'Triggered By': isDefaultDate ? 'Automatic' : 'User',
		'Discounted Seat Available': hasDiscount,
		'Available Tours': availableToursCount ?? null,
		...otherProps,
	};
	if (prevDate) {
		properties['Previous Experience Date'] = prevDate;
		properties['Previous Experience Lead Days'] = countNoOfDaysFromDate(
			date,
			prevDate,
		);
	}
	trackEvent(properties);
};

export const trackMwebDrawer = ({
	isOpen,
	flowType,
	tgid,
	type,
	eventLabelStart,
	...otherProps
}: any) => {
	trackEvent({
		eventName: `${
			eventLabelStart ? eventLabelStart : 'Select Page Drawer'
		} ${isOpen ? 'Open' : 'Closed'}`,
		'Tour Group ID': tgid,
		'Flow Type': flowType,
		'Drawer Type': type,
		...otherProps,
	});
};

export const trackBookingFlowCTAClick = ({
	label,
	tgid,
	drawerType = null,
	flowType = null,
	ranking = null,
	...otherProps
}: {
	label?: string;
	tgid?: string | number;
	drawerType?: string | null;
	flowType?: string | null;
	ranking?: any;
	[otherProps: string]: any;
}) => {
	trackEvent({
		eventName: 'Select Page CTA Clicked',
		label,
		'Drawer Type': drawerType,
		'Tour Group ID': Number(tgid),
		'Flow Type': flowType,
		Ranking: ranking,
		...otherProps,
	});
};

export const getExperiencePageEventCommonProperties = (
	product: any,
	currentCityCode?: string,
) => {
	const {
		language: lang,
		id: tgid,
		city: { code },
		listingPrice,
	} = product;
	const { finalPrice, currencyCode, originalPrice } = listingPrice ?? {};

	return {
		[ANALYTICS_PROPERTIES.TGID]: Number(tgid),
		[ANALYTICS_PROPERTIES.CITY]: currentCityCode ?? code,
		[ANALYTICS_PROPERTIES.DISPLAY_PRICE]: finalPrice,
		[ANALYTICS_PROPERTIES.DISPLAY_CURRENCY]: currencyCode,
		[ANALYTICS_PROPERTIES.DISCOUNT]: finalPrice < originalPrice,
		Language: lang,
	};
};

export const clearLastVisitedCollectionId = () =>
	remove(ANALYTICS_PROPERTIES.COLLECTION_ID);

export const clearLastVisitedCollectionValue = () =>
	remove(ANALYTICS_PROPERTIES.COLLECTION_NAME);

export const getFireAdwordConversionParams = ({
	pricePayableUsd,
	itineraryId,
}: any) => {
	return {
		adwords_conversion_value: pricePayableUsd,
		adwords_currency_code: 'USD',
		adwords_order_id: itineraryId,
	};
};

export const setBookingFlowVariablesInDataLayer = (
	product: any,
	pricing: any,
	currencyCode: any,
) => {
	if (!product || !pricing || !currencyCode) return;
	const { name: productName } = product;
	const primaryCollectionName = getPrimaryCollectionName(product);
	const primaryCollectionId = getPrimaryCollectionId(product);
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.CURRENCY,
		value: currencyCode,
	});
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.PRODUCT_NAME,
		value: productName,
	});
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.PRIMARY_COLLECTION_NAME,
		value: primaryCollectionName,
	});
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.PRIMARY_COLLECTION_ID,
		value: primaryCollectionId,
	});
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.FLOW_TYPE,
		value: getAnalyticsFlowType(product),
	});
};

export const setCustomDimensions = (values: any) => {
	const {
		hsId,
		platform,
		isSignedIn,
		host,
		path,
		currentCity,
		countryDisplayName,
		currentLanguage = 'EN',
		currencyCode,
		pageId,
		lastVisitedCollectionId,
		lastVisitedCollectionName,
		experiment,
		tgid,
		experienceName,
		collectionId,
		collectionName,
		categoryId,
		categoryName,
		subCategoryId,
		subCategoryName,
		flowType,
		averageRating,
		reviewCount,
		currentPageType,
		layoutType,
	} = values;
	const dimensionsMap = {
		[ANALYTICS_PROPERTIES.PLATFORM_NAME]: platform,
		[ANALYTICS_PROPERTIES.SIGNED_IN]: isSignedIn,
		[ANALYTICS_PROPERTIES.HSID]: hsId,
		[ANALYTICS_PROPERTIES.CITY]: currentCity,
		[ANALYTICS_PROPERTIES.LANGUAGE]: currentLanguage,
		[ANALYTICS_PROPERTIES.PAGE_TYPE]: currentPageType,
		[ANALYTICS_PROPERTIES.LAYOUT_TYPE]: layoutType,
		[ANALYTICS_PROPERTIES.CURRENCY]: currencyCode || null,
		[ANALYTICS_PROPERTIES.COLLECTION_ID]: lastVisitedCollectionId,
		[ANALYTICS_PROPERTIES.COLLECTION_NAME]: lastVisitedCollectionName,
		[ANALYTICS_PROPERTIES.PAGE_ID]: pageId,
		[ANALYTICS_PROPERTIES.DOMAIN]: host,
		[ANALYTICS_PROPERTIES.PAGE_URL]: `${host}${path}`,
		[ANALYTICS_PROPERTIES.EXPERIMENT]: experiment,
		[ANALYTICS_PROPERTIES.FLOW_TYPE]: flowType,
		[ANALYTICS_PROPERTIES.TGID]: Number(tgid) ?? null,
		[ANALYTICS_PROPERTIES.EXPERIENCE_NAME]: experienceName,
		[ANALYTICS_PROPERTIES.COUNTRY]: countryDisplayName,
		[ANALYTICS_PROPERTIES.COLLECTION_ID]: collectionId,
		[ANALYTICS_PROPERTIES.COLLECTION_NAME]: collectionName,
		[ANALYTICS_PROPERTIES.CATEGORY_ID]: categoryId,
		[ANALYTICS_PROPERTIES.CATEGORY_NAME]: categoryName,
		[ANALYTICS_PROPERTIES.SUB_CATEGORY_ID]: subCategoryId,
		[ANALYTICS_PROPERTIES.SUB_CATEGORY_NAME]: subCategoryName,
		[ANALYTICS_PROPERTIES.AVERAGE_RATING]: averageRating,
		[ANALYTICS_PROPERTIES.NO_OF_REVIEWS]: reviewCount,
		[ANALYTICS_PROPERTIES.GOOGLE_REMARKETING.DRM_CUSTOM_PARAMS]: {
			[ANALYTICS_PROPERTIES.CITY]: currentCity,
			[ANALYTICS_PROPERTIES.LANGUAGE]: currentLanguage,
			[ANALYTICS_PROPERTIES.CURRENCY]: currencyCode || null,
			[ANALYTICS_PROPERTIES.PAGE_TYPE]: currentPageType,
			[ANALYTICS_PROPERTIES.SIGNED_IN]: isSignedIn,
			[ANALYTICS_PROPERTIES.GOOGLE_REMARKETING.GOOGLE_BUSINESS_VERTICAL]:
				'custom',
		},
	};
	Object.keys(dimensionsMap)
		.filter(k => dimensionsMap[k])
		.forEach(key => {
			sendVariableToDataLayer({
				name: key,
				value: dimensionsMap[key],
			});
		});
};

// Updating Custom Dimension by saving it to local Storage  : Last Visited Category
export const storeLastVisitedCollection = (collection: any) => {
	const { displayName, id } = collection;
	write(ANALYTICS_PROPERTIES.COLLECTION_ID, id);
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.COLLECTION_ID,
		value: id,
	});
	write(ANALYTICS_PROPERTIES.COLLECTION_NAME, displayName);
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.COLLECTION_NAME,
		value: displayName,
	});
};

export const getLastVisitedCollectionId = () =>
	read(ANALYTICS_PROPERTIES.COLLECTION_ID);

export const getLastVisitedCollectionName = () =>
	read(ANALYTICS_PROPERTIES.COLLECTION_NAME);

export const getTopProductIds = (productIds: any) => {
	if (productIds) {
		const topProductIds = productIds;
		let top10ProductIds = topProductIds;
		if (topProductIds.length > 10) {
			top10ProductIds = topProductIds.splice(0, 10);
		}
		return top10ProductIds;
	}
	return null;
};

const getSectionIndex = (target: any) => {
	if (!target) return null;
	if (target.dataset?.sectionRank) return +target.dataset.sectionRank;

	const allSections = document.querySelectorAll(
		'.core-section-header-container',
	);
	const currentIndex = Array.from(allSections)
		.map((node, index) => {
			(node as any).dataset.sectionRank = index + 1;
			return node;
		})
		.findIndex(node => target.isSameNode(node));
	return currentIndex + 1;
};

/**
 * Needs the tracked child element to have
 * `core-section-header-container` className for
 * correct `ranking` calculation.
 */
export const trackSectionViews = (
	eventName: string,
	sectionName: string,
	index?: any,
) => {
	let hasFiredOnce = false;
	return (ref: any) => {
		if (!index) index = getSectionIndex(ref.current);
		if (hasFiredOnce) return;
		hasFiredOnce = true;
		trackEvent({
			eventName: eventName,
			Ranking: index,
			Section: sectionName,
		});
	};
};

export const checkIsMicroband = (mbName?: string) =>
	mbName === 'Headout' ? null : mbName;

export const trackSearchSuggestionsViewed = ({
	searchLocation,
	query,
	cityResultsLength,
	productResultsLength,
	collectionResultsLength,
	loadTime,
}: {
	searchLocation: string;
	query: string;
	cityResultsLength: number;
	productResultsLength: number;
	collectionResultsLength: number;
	loadTime: number;
}) => {
	const numOfSuggestions =
		cityResultsLength + productResultsLength + collectionResultsLength;
	trackEvent({
		eventName: ANALYTICS_EVENTS.SEARCH.SUGGESTIONS_VIEWED,
		Placement: searchLocation,
		[ANALYTICS_PROPERTIES.NUMBER_OF_SUGGESTIONS]: numOfSuggestions,
		Query: query,
		[ANALYTICS_PROPERTIES.NUMBER_OF_CITY_SUGGESTIONS]: cityResultsLength,
		[ANALYTICS_PROPERTIES.NUMBER_OF_EXPERIENCE_SUGGESTIONS]:
			productResultsLength,
		[ANALYTICS_PROPERTIES.NUMBER_OF_COLLECTION_SUGGESTIONS]:
			collectionResultsLength,
		[ANALYTICS_PROPERTIES.LOAD_TIME]: loadTime,
	});
};

export const handleSearchResultsClick = ({
	rank = null,
	searchType,
	resultTitle = null,
	query,
	searchLocation,
}: {
	rank?: number | null;
	searchType: string;
	resultTitle?: string | null;
	query: string;
	searchLocation: string;
}) => {
	let suggestionType = searchType;

	switch (searchType) {
		case SEARCH_ENTITY_TYPE.CITY:
			suggestionType = ANALYTICS_PROPERTIES.CITY;
			break;
		case SEARCH_ENTITY_TYPE.COLLECTION:
			suggestionType = ANALYTICS_PROPERTIES.COLLECTION;
			break;
		case SEARCH_ENTITY_TYPE.PRODUCT:
			suggestionType = ANALYTICS_PROPERTIES.EXPERIENCE;
			break;
		default:
			break;
	}

	trackEvent({
		eventName: ANALYTICS_EVENTS.SEARCH.SUGGESTION_CLICKED,
		[ANALYTICS_PROPERTIES.SUGGESTION_TYPE]: suggestionType,
		Query: query,
		Ranking: rank,
		[ANALYTICS_PROPERTIES.SUGGESTION_STATE]:
			resultTitle || ANALYTICS_PROPERTIES.USER_QUERY,
		[ANALYTICS_PROPERTIES.PLACEMENT]: searchLocation,
	});
};

export const handleSearchBarDropdownEvent = ({
	initialState,
	placement,
}: any) => {
	trackEvent({
		eventName: ANALYTICS_EVENTS.SEARCH.STARTED,
		[ANALYTICS_PROPERTIES.INITIAL_STATE]: initialState,
		...(placement && { [ANALYTICS_PROPERTIES.PLACEMENT]: placement }),
	});
};

type TOrNull<T> = T | null;

export const getCommonUpsellAnalyticsProperties = (
	{
		averageRating = null,
		comboDiscount = null,
		comboTourGroupId = null,
		currency = null,
		finalPrice = null,
		productIndex = null,
		ratingCount = null,
		recommendedProductsCount = null,
	}: {
		averageRating?: TOrNull<number>;
		comboDiscount?: TOrNull<number>;
		comboTourGroupId?: TOrNull<number>;
		currency?: TOrNull<string>;
		finalPrice?: TOrNull<number>;
		productIndex?: TOrNull<number>;
		ratingCount?: TOrNull<number>;
		recommendedProductsCount?: TOrNull<number>;
	},
	customProperties?: { [key: string]: string | number },
) => {
	return {
		[ANALYTICS_PROPERTIES.COMBO_UPSELL.TGID]: comboTourGroupId,
		[ANALYTICS_PROPERTIES.COMBO_UPSELL.COMBO_DISCOUNT]: comboDiscount,
		[ANALYTICS_PROPERTIES.COMBO_UPSELL.CURRENCY]: currency,
		[ANALYTICS_PROPERTIES.COMBO_UPSELL.PRICE]: finalPrice,
		[ANALYTICS_PROPERTIES.COMBO_UPSELL.AVERAGE_RATING]: averageRating,
		[ANALYTICS_PROPERTIES.COMBO_UPSELL.RATING_NO]: ratingCount,
		[ANALYTICS_PROPERTIES.RANKING]: productIndex,
		[ANALYTICS_PROPERTIES.COMBO_UPSELL.COUNT_PRODUCTS]:
			recommendedProductsCount,
		...customProperties,
	};
};

export const trackCityBannerClick = ({
	index,
	link,
}: {
	index: number;
	link?: string;
}) => {
	trackEvent({
		eventName: ANALYTICS_EVENTS.CITY_PAGE_BANNER_CLICKED,
		[ANALYTICS_PROPERTIES.RANKING]: index + 1,
		...(link ? { [ANALYTICS_PROPERTIES.LINK]: link } : {}),
	});
};

export const getFingerprintVisitorId = async (extendedResult = true) => {
	try {
		const isProductionEnv =
			isProductionEnvironment() ||
			process.env.NEXT_PUBLIC_BASE_URL?.includes(HEADOUT_DOMAIN);

		const fingerPrintConfig = isProductionEnv
			? FINGERPRINT_CONFIG.LIVE
			: FINGERPRINT_CONFIG.TEST;

		const fingerprint = await FingerprintJS.load({
			apiKey: fingerPrintConfig.PUBLIC_KEY,
			scriptUrlPattern: [
				fingerPrintConfig.SCRIPT_PATTERN,
				FingerprintJS.defaultScriptUrlPattern,
			],
			endpoint: [
				fingerPrintConfig.ENDPOINT,
				FingerprintJS.defaultEndpoint,
			],
		});

		const result = await fingerprint.get({
			extendedResult,
			timeout: 30000,
		});

		return result;
	} catch (error) {
		sendException({
			exception: 'Fingerprint Error',
			hint: {
				extra: {
					error,
				},
			},
			methodName: 'getFingerprintVisitorId',
		});
		trackEvent({
			eventName: 'Fingerprint Error Occurred',
			Error: JSON.stringify(error),
		});

		return null;
	}
};

export const trackItineraryEditButtonClicked = (type: string) =>
	trackEvent({
		eventName: 'Itinerary Card Edit Button Clicked',
		[ANALYTICS_PROPERTIES.TYPE]: type,
	});

export const trackSectionViewedEvent = (
	sectionIds: string[],
	threshold = 0.25,
) => {
	const observedElements = sectionIds.map(id => document.getElementById(id));
	const observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					const rank = sectionIds.indexOf(entry.target.id) + 1;
					trackEvent({
						eventName:
							ANALYTICS_EVENTS.MICROSITE_PAGE_SECTION_VIEWED,
						[ANALYTICS_PROPERTIES.SECTION]: entry.target.id,
						[ANALYTICS_PROPERTIES.RANKING]: rank,
					});
					observer.unobserve(entry.target);
				}
			});
		},
		{ threshold },
	);

	observedElements.forEach(element => {
		if (element) {
			observer.observe(element);
		}
	});
};
