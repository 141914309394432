import type { TAirportTransfersSearchActions } from 'Actions/airportTransferSearch';
import {
	MWEB_NEXT_STEP,
	MWEB_PREV_STEP,
	SET_AIRPORT_DATA,
	SET_AIRPORTS_LIST,
	SET_LOCATION_DATA,
	SET_MWEB_CURRENT_FORM_STEP,
	SET_PAX_AND_LUGGAGE,
	SET_PICKUP_DATE,
	SET_PICKUP_TIME,
	SET_STATE,
	SET_TRANSFER_TYPE,
} from 'Actions/airportTransferSearch';

export type TTransferType = 'AIRPORT_DROPOFF' | 'AIRPORT_PICKUP';

export type TSearchFormStep =
	| null
	| 'sourceAddress'
	| 'destinationAddress'
	| 'paxAndLuggage'
	| 'pickupDate'
	| 'pickupTime';

export type TAirportTransfersSearchFormState = {
	currentFormStep: TSearchFormStep;
	airportData: {
		iataCode: string | null;
		airportName: string;
		tourGroupId: number;
	};
	locationData: {
		lat: number;
		long: number;
		addressText: string;
	};
	paxAndLuggage: {
		PASSENGERS: number;
	};
	pickupDate: string;
	pickupTime: string;
	transferType: TTransferType;
	airportsList: {
		name: string;
		iataCode: string;
		tourGroupId: number;
	}[];
};

const initialState: TAirportTransfersSearchFormState = {
	transferType: 'AIRPORT_PICKUP',
	currentFormStep: null,
	airportData: {
		airportName: '',
		iataCode: null,
		tourGroupId: 0,
	},
	locationData: {
		lat: 0,
		long: 0,
		addressText: '',
	},
	paxAndLuggage: {
		PASSENGERS: 1,
	},
	pickupDate: '',
	pickupTime: '',
	airportsList: [],
};

const formStepsOrder: TSearchFormStep[] = [
	'sourceAddress',
	'destinationAddress',
	'pickupDate',
	'pickupTime',
	'paxAndLuggage',
];

export const airportTransfersBookingDataStore = (
	state: TAirportTransfersSearchFormState = initialState,
	action: TAirportTransfersSearchActions,
): TAirportTransfersSearchFormState => {
	const currentFormStepIndex = formStepsOrder.indexOf(state.currentFormStep);
	switch (action.type) {
		case MWEB_NEXT_STEP:
			const nextIndex = currentFormStepIndex + 1;
			return {
				...state,
				currentFormStep:
					nextIndex > formStepsOrder.length
						? null
						: formStepsOrder[nextIndex],
			};
		case MWEB_PREV_STEP:
			const prevIndex = currentFormStepIndex - 1;
			return {
				...state,
				currentFormStep:
					prevIndex < 0 ? null : formStepsOrder[prevIndex],
			};
		case SET_MWEB_CURRENT_FORM_STEP:
			return { ...state, currentFormStep: action.payload };
		case SET_AIRPORT_DATA:
			return {
				...state,
				airportData: action.payload,
			};

		case SET_LOCATION_DATA:
			return {
				...state,
				locationData: {
					...state.locationData,
					...action.payload,
				},
			};
		case SET_PAX_AND_LUGGAGE:
			return {
				...state,
				paxAndLuggage: {
					...state.paxAndLuggage,
					...action.payload,
				},
			};
		case SET_PICKUP_DATE:
			return { ...state, pickupDate: action.payload };
		case SET_PICKUP_TIME:
			return { ...state, pickupTime: action.payload };
		case SET_STATE:
			return { ...state, ...action.payload };
		case SET_TRANSFER_TYPE:
			return {
				...state,
				transferType: action.payload,
			};
		case SET_AIRPORTS_LIST:
			return { ...state, airportsList: action.payload };

		default:
			return state;
	}
};
