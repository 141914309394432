import type { Dispatch, ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';

import type {
	ProductCardAction,
	State as ProductCardState,
} from 'Reducers/productCard';
import {
	initialState as productCardInitialState,
	productCardReducer,
} from 'Reducers/productCard';

type ProductCardContextType = {
	state: ProductCardState;
	dispatch: Dispatch<ProductCardAction>;
};

const ProductCardContext = createContext<ProductCardContextType>({
	state: productCardInitialState,
	dispatch: () => {},
} as ProductCardContextType);

export const useProductCardContext = () => {
	return useContext(ProductCardContext);
};

type Props = {
	children: ReactNode;
};

export const ProductCardProvider = ({ children }: Props) => {
	const [state, dispatch] = useReducer(
		productCardReducer,
		productCardInitialState,
	);

	return (
		<ProductCardContext.Provider value={{ state, dispatch }}>
			{children}
		</ProductCardContext.Provider>
	);
};
